import React, { useState } from 'react'
import { InputBoxWraper } from '../../constents/components';

const Email = ({ email, setEmail, setEmailValid }) => {

    const [isEmailCorrect, setIsEmailCorrect] = useState(false);
    return (
        <div>
            <div className=' flex gap-2 items-end'>
                {/* <p className=' font-semibold'>*Email </p> */}
                {!isEmailCorrect && email.length > 0 && (<p className=' text-sm text-red-700 font-semibold'>Please enter a valid email address </p>)}
            </div>
            <InputBoxWraper>
            <input
                className='outline-none rounded-md bg-[#f0f0f0] w-full'
                type='text'
                placeholder='Email'
                value={email}
                onChange={(e) => {
                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    setEmail(e.target.value);
                    setIsEmailCorrect(emailRegex.test(e.target.value));
                    if (setEmailValid) { setEmailValid(!emailRegex.test(e.target.value)) }
                }}
            />
            </InputBoxWraper>
        </div>
    )
}
export default Email;