import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import useFetchJob from '../../Hooks/jobs/useFetchJob';
import { ButtonWithoutIcon, InputBoxWraper, Job_Tag_Box, Loader, TopBottomBorder } from '../../constents/components';
import { alphanumericDate } from '../../constents/functions';



const x =
{
    "Company_Details": {
        "Company_Name": "Infosys",
        "Company_Website": "https://www.infosys.com",
        "Website_Logo": "https://www.infosys.com/images/infosys-logo.svg",
        "Company_Description": "Infosys is a global leader in next-generation digital services and consulting.",
        "Contact_Phone": "9999999999",
        "Contact_Email": "recruitment@infosys.com",
        "Industry": "Information Technology"
    },
    "About_Job": {
        "JD": "• Design, develop, test, and maintain software applications, systems, and tools • Write high-quality, efficient, and well-documented code in various programming languages • Participate in code reviews and contribute to the improvement of the overall codebase • Collaborate with cross-functional teams to identify and prioritize project requirements • Troubleshoot and debug software issues, identifying and resolving defects quickly • Stay up-to-date with industry trends, best practices, and new technologies • Develop and maintain technical documentation and user manuals • Work on multiple projects simultaneously, meeting deadlines and delivering results on time",
        "Job_Type": "Full Time",
        "Skills": [
            "Java",
            "Spring Boot",
            "Cloud Computing",
            "Microservices",
            "Database Design",
            "Agile"
        ],
        "Experience": "3",
        "Min_Salary": 60000,
        "Max_Salary": 90000,
        "No_Of_Openings": "5",
        "Fixed_Salary": null,
        "Work_Type": "On-site",
        "Shift": "Night",
        "Working_Hours": "9",
        "Working_Days": "5"
    },
    "_id": "6736eb307a82832d78f7a8fd",
    "Hr_Id": "e2f7c875-34e1-413f-8d32-3e91930b6330",
    "Job_Title": "Software Engineer",
    "Status": "Active",
    "Aplication_Link": "www.infosys.hiring.com",
    "Created_At": 1731312410,
    "Location": [
        "Bangalore",
        "Hyderabad",
        "Pune"
    ],
    "Job_Id": "d369dd88-bf88-4f03-badb-0437361fdc14"
}



const FullJobDetails = () => {
    const [query] = useSearchParams();
    console.log(query.get('jid'));
    const [loading, error, job] = useFetchJob(query.get('jid'));

    const jobTitle = job?.Job_Title;
    const JobSummary = job?.About_Job?.Job_Summary;
    const JobDescription = job?.About_Job?.JD;
    const companyProfileImgUrl = job?.Company_Details?.Company_Profile;
    const companyName = job?.Company_Details?.Company_Name;
    const companySummary = job?.Company_Details?.Company_Description;
    const location = job?.Location?.[0];
    const jobPostingTime = `Posted at ${alphanumericDate(job?.Created_At * 1000)}`;
    const minSalary = job?.About_Job?.Min_Salary;
    const maxSalary = job?.About_Job?.Max_Salary;
    const JobType = job?.About_Job?.Job_Type;
    const companyUrl = '';
    const email = job?.Company_Details?.Contact_Email;
    const contactNumber = job?.Company_Details?.Contact_Phone;
    const companyIndustry = job?.Company_Details?.Industry;
    const skills = job?.About_Job?.Skills;
    const budget = `${minSalary} - ${maxSalary}`
    const overview = ['Full Time', 'Min. 3 yrs of Experience', 'Salary : 60k 70k / m', 'Remote • India'];



    const list = [
        {
            key: 'Budget',
            value: <>{budget} <span>/ year</span></>
        },
        {
            key: 'Role',
            value: JobType,
        },
        {
            key: 'Locations',
            value: location,
        },
    ]
    
    return loading ? (<Loader />) : 
        <div className=' w-full p-10'>
            <div className=' flex justify-between'>
                <div>
                    <h1 className=' text-2xl font-semibold'>{jobTitle}</h1>
                    <p className=' text-sm'>{jobPostingTime}</p>
                </div>

                <div className=' flex gap-2'>
                    < ButtonWithoutIcon text={"Share"} classNames={"px-8 py-1 text-black bg-white border-[1px]"} />
                    < ButtonWithoutIcon text={"Apply"} classNames={"px-8 py-1 text-white"} />
                </div>
            </div>
            <div className=' flex gap-8 mt-4'>
                <div className=' flex-1 mt-4 py-4 border-t-[1px] rounded-lg '>
                    <h1 className=' text-lg font-semibold'>{'Job Description'}</h1>

                    <h4 className=' mt-6 font-semibold'>{'Responsibilities'}</h4>
                    <ul>
                        {JobDescription?.split('•')?.filter(item => item?.trim()).map((item, index) => (<li key={index}>{'•' + item}</li>))}
                    </ul>

                    <h4 className=' mt-6 font-semibold'>{'Requirements'}</h4>
                    <ul>
                        {JobDescription?.split('•')?.filter(item => item?.trim()).map((item, index) => (<li key={index}>{'•' + item}</li>))}
                    </ul>

                    {/*  */}
                    <p className=' text-sm mt-8 mb-2'>Overview</p>
                    <div className=' flex gap-3 flex-wrap'>{overview?.map((item, index) => (<Job_Tag_Box key={index} text={item} />))}</div>

                    {/*  */}
                    <p className=' text-sm mt-8 mb-2'>Required Skills</p>
                    <div className=' flex gap-3 flex-wrap'>{skills?.map((item, index) => (<Job_Tag_Box key={index} text={item} />))}</div>

                    {/*  */}
                    <p className=' text-sm mt-8 mb-2'>Full Address</p>
                    <Job_Tag_Box text={location} />
                </div>





                {/* company Information */}
                <div
                    className=' p-6 h-fit border-[1px] rounded-2xl w-1/3'>
                    <h1 className=' text-md font-semibold'>Company Information</h1>
                    <div className=' flex justify-between items-center mt-6 mb-6'>
                        <div className=' items-center'>
                            <img className=' w-24 h-16 border-[1px] ' src={companyProfileImgUrl} alt="companyImage" />
                            <h1 className='mt-1 text-md'>{companySummary}</h1>
                        </div>
                        {/* <a
                            className=' text-sm'
                            href={companyUrl}
                        >Website</a> */}
                    </div>
                    <Job_Tag_Box text={companyIndustry} />

                    <h3 className=' mt-6 font-medium text-sm'>Contact Number</h3>
                    <p className=' text-base'>{contactNumber}</p>

                    <h3 className=' mt-6 font-medium text-sm'> Email ID</h3>
                    <p className=' text-base'>{email}</p>
                </div>
            </div>
        </div>

}

export default FullJobDetails;