import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogOut from '../../Hooks/useLogOut';
import { PROFILE_URL } from '../../constents/placeholder';
import { useSelector } from 'react-redux';

const LoggedIn = () => {
    const [showBox, setShowBox] = useState(false);
    const Navigate = useNavigate();
    const [loading, error, logoutFunction] = useLogOut();
    const profileUrl = useSelector((store) => store?.user?.profilePicture);
    return (
        <div className='relative'>
            <img src={profileUrl ?? PROFILE_URL}
                onClick={() => setShowBox(pre => !pre)}
                className=' w-10 h-10 rounded-full cursor-pointer'
                alt="profile" />
            {showBox && (
                <div className=' absolute flex flex-col gap-1 p-2'>
                    <span onClick={() => logoutFunction()}>LogOut</span>
                    {/* <span onClick={() => Navigate('/profile')}>view Profile</span>
                    <span>Setting</span> */}
                </div>)}

        </div>
    )
}

export default LoggedIn;