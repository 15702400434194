import React, { useReducer, useState } from 'react'
import RegistorMain from './RegistorComponents/RegistorMain';
import useRegister from '../../Hooks/Registration/useRegister';
import OtpComponent from './RegistorComponents/OtpComponent';
import FirstDetails from './RegistorComponents/FirstDetails';
import CvStep from './RegistorComponents/CvStep';
import IntrestUpdate from './RegistorComponents/IntrestUpdate';
import useCreateProfile from '../../Hooks/Registration/useCreateProfile';
import Designation from './RegistorComponents/Designation';
import Profile from './RegistorComponents/Profile';
import arrowIcon from '../../Assets/arrow_back.svg';
import { AuthStepsHeading, AuthStepsParagraph } from '../../constents/components';

const BackButton = ({setState})=>{

    return(
        <div className=' flex justify-start'>
            <img 
            onClick={()=>setState(pre => pre-1)}
            src={arrowIcon}
            className=' cursor-pointer'
            alt="backButton" 
            />
        </div>
    )
}

const ProgressBar = ({width})=>{

    return (<div className='mt-10 mb-6 h-1 w-full bg-[#f0f0f0] relative overflow-hidden rounded-sm'>
    <span
        className=' absolute top-0 left-0 h-full bg-black'
        style={{ width }}
    >
    </span>
</div>)
}

const Register = () => {
    const [loading, error, isUserCreated, registerFunction] = useRegister();
    const [updateDetailsLoading, updateDetailsError, someState, proofileUpdater] = useCreateProfile();

    const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
    const UPDATE_BASIC_DETAILS = 'UPDATE_BASIC_DETAILS'
    const UPDATE_CV_LINKEDIN = 'UPDATE_CV_LINKEDIN';
    const UPDATE_INTEREST = 'UPDATE_INTEREST';
    const UPDATE_EMAIL = 'UPDATE_EMAIL';
    const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
    const UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME';
    const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME';
    const UPDATE_GENDER = 'UPDATE_GENDER';
    const UPDATE_DESIGNATION = 'UPDATE_DESIGNATION';
    const UPDATE_CITY = 'UPDATE_CITY';
    const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
    const UPDATE_PROFILE = 'UPDATE_PROFILE';
    const UPDATE_CV = 'UPDATE_CV';
    const UPDATE_LINKEDIN = 'UPDATE_LINKEDIN';
    const UPDATE_CURRENT_CTC = 'UPDATE_CURRENT_CTC';
    const UPDATE_EXPECTED_CTC = 'UPDATE_EXPECTED_CTC';

    const initials = {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        gender: '',
        designation: '',
        city: '',
        country: '',
        linkedin: '',
        interest: [],
        profile: '',
        cv: {},
        currentCtc: 0,
        expectedCtc: 0,
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case UPDATE_PROFILE_IMAGE: {
                return { ...state, profile: action.payload };
            };
                break;
            case UPDATE_BASIC_DETAILS: {
                return { ...state, ...action.payload };
            };
                break;
            case UPDATE_CV_LINKEDIN: {
                return { ...state, ...action.payload };
            };
                break;
            case UPDATE_INTEREST: {
                return { ...state, interest: action.payload };
            };
            case UPDATE_PASSWORD: {
                return { ...state, password: action.payload };
            };
            case UPDATE_EMAIL: {
                return { ...state, email: action.payload };
            };
            case UPDATE_FIRST_NAME: {
                return { ...state, firstName: action.payload };
            };
            case UPDATE_LAST_NAME: {
                return { ...state, lastName: action.payload };
            };
            case UPDATE_GENDER: {
                return { ...state, gender: action.payload };
            };
            case UPDATE_DESIGNATION: {
                return { ...state, designation: action.payload };
            };
            case UPDATE_CITY: {
                return { ...state, city: action.payload };
            };
            case UPDATE_COUNTRY: {
                return { ...state, country: action.payload };
            };
            case UPDATE_CV: {
                return { ...state, cv: action.payload };
            };
            case UPDATE_LINKEDIN: {
                return { ...state, linkedin: action.payload };
            };
            case UPDATE_CURRENT_CTC: {
                return { ...state, currentCtc: action.payload };
            };
            case UPDATE_EXPECTED_CTC: {
                return { ...state, expectedCtc: action.payload };
            };
                break;
            default: { return { ...state } };
        }
    };

    const updateEmail = (value) => dispatch({ type: UPDATE_EMAIL, payload: value });
    const updatePassword = (value) => dispatch({ type: UPDATE_PASSWORD, payload: value });

    const updateFirstName = (value) => dispatch({ type: UPDATE_FIRST_NAME, payload: value });
    const updateLastName = (value) => dispatch({ type: UPDATE_LAST_NAME, payload: value });
    const updateGender = (value) => dispatch({ type: UPDATE_GENDER, payload: value });
    const updatecity = (value) => dispatch({ type: UPDATE_CITY, payload: value });
    const updateCountry = (value) => dispatch({ type: UPDATE_COUNTRY, payload: value });
    const updateInterest = (value) => dispatch({ type: UPDATE_INTEREST, payload: value });

    const updateDesignation = (value) => dispatch({ type: UPDATE_DESIGNATION, payload: value });
    const updateCurrentCTC = (value) => dispatch({ type: UPDATE_CURRENT_CTC, payload: value });
    const updateExpectedCTC = (value) => dispatch({ type: UPDATE_EXPECTED_CTC, payload: value });

    const updateProfileImage = (url) => dispatch({ type: UPDATE_PROFILE_IMAGE, payload: url });

    const updateCv = (value) => dispatch({ type: UPDATE_CV, payload: value });
    const updateLinkedin = (value) => dispatch({ type: UPDATE_LINKEDIN, payload: value });
    const uploadProfileDetails = () => proofileUpdater(state);

    const [state, dispatch] = useReducer(reducer, initials);

    const [currentStep, setCurrentStep] = useState(0);
    const sendToNextStep = ()=>setCurrentStep(pre => pre+1);

    console.log(state);

    const list = [
        <RegistorMain
            updateEmail={updateEmail}
            updatePassword={updatePassword}
            details={state}
            sendToNextStep={sendToNextStep}
        />,

        <>  
            <BackButton setState={setCurrentStep}/>
            <AuthStepsHeading text="Enter OTP" />
            <AuthStepsParagraph text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, similique sunt ipsam esse qui a ullam recusandae consequuntur." />
            <OtpComponent
                details={state}
                sendToNextStep={sendToNextStep}
            />
        </>
        ,
        <>
            <BackButton setState={setCurrentStep}/>
            <AuthStepsHeading text="Create your workspace" />
            <AuthStepsParagraph text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, similique sunt ipsam esse qui a ullam recusandae consequuntur." />
            <ProgressBar width={'05%'} />
            <FirstDetails
                details={state}
                sendToNextStep={sendToNextStep}
                updateFunctions={{ updateFirstName, updateLastName, updateGender, updatecity, updateCountry }}
            />
        </>,

        <>
            <BackButton setState={setCurrentStep}/>
            <AuthStepsHeading text="Create your workspace" />
            <AuthStepsParagraph text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, similique sunt ipsam esse qui a ullam recusandae consequuntur." />
            <ProgressBar width={'30%'} />
            <Designation
            details={state}
            sendToNextStep={sendToNextStep}
            updateFunctions={{ updateDesignation, updateExpectedCTC, updateCurrentCTC }}
        />
        </>,
        
        <>
            <BackButton setState={setCurrentStep}/>
            <AuthStepsHeading text="Create your workspace" />
            <AuthStepsParagraph text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, similique sunt ipsam esse qui a ullam recusandae consequuntur." />
            <ProgressBar width={'55%'} />
            <Profile
                details={state}
                sendToNextStep={sendToNextStep}
                updateFunctions={{ updateProfileImage }}
            />
        </>,

        <>
            <BackButton setState={setCurrentStep}/>
            <AuthStepsHeading text="Create your workspace" />
            <AuthStepsParagraph text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, similique sunt ipsam esse qui a ullam recusandae consequuntur." />
            <ProgressBar width={'80%'} />
            <CvStep
                details={state}
                sendToNextStep={sendToNextStep}
                updateFunctions={{ updateCv, updateLinkedin }}
            />
        </>,

        <>
            <BackButton setState={setCurrentStep}/>
            <AuthStepsHeading text="Create your workspace" />
            <AuthStepsParagraph text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, similique sunt ipsam esse qui a ullam recusandae consequuntur." />
            <ProgressBar width={'98%'} />
            <IntrestUpdate
                details={state}
                sendToNextStep={uploadProfileDetails}
                updateFunctions={{ updateInterest }}
                loading={updateDetailsLoading}
            />
        </>,
    ];

    return (
        <div>
            {list[currentStep]}
        </div>
    )
}
export default Register;