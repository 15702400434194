import React, { useState } from 'react'
import useRequestForgotPassword from '../../Hooks/useRequestForgotPassword';
import { FORGOT_PASSWORD_REQUEST_SUCCESSFUL, PASSWORD_UPDATE_SUCCESSFUL } from '../../constents/authStrings';

const ForgotPassword = () => {

    const [loading, error, success, requestFunction] = useRequestForgotPassword();
    const [email, setEmail] = useState('');


    const submitReuest = async (e) => {
        e.preventDefault();
        try {
            const success = await requestFunction(email);
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className=' h-screen w-screen flex justify-center items-center'>
            <div className=' overflow-y-auto rounded-xl shadow-xl items-center p-8 min-h-[420px] bg-[#fff] border-[1px] border-[#f1f1f1] w-[24rem]'>
                <div className='fadeIncrease'>
                    <h1 className=" text-3xl mb-4 font-bold">
                        <span className=" text-primaryBlue">Forgot Password</span>
                    </h1>
                    <form onSubmit={(e) => submitReuest(e)} className="flex flex-col gap-4">
                        <div className=" flex flex-col gap-2 lg:gap-6">
                            <div className=" flex-1 flex flex-col gap-2">
                                <div className=" flex gap-2 items-end">
                                    <p className=" text-md capitalize text-[#000000] font-medium">
                                        email
                                    </p>
                                    {/* <span className={`text-sm text-[#ff0000]`}>
                                        {errors.password && touched.password ? errors.password : " "}
                                    </span> */}
                                </div>
                                <input
                                    className=' border-2 border-primary_700 px-2 py-1 rounded-md'
                                    placeholder={"Email"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                        </div>
                        {loading ? (<span>loading...</span>) : (<> {
                            success ? (<span>{FORGOT_PASSWORD_REQUEST_SUCCESSFUL}</span>) : (<button type="submit" className=" px-12 font-semibold py-2 border rounded-full bg-buttonCTA ]">Request</button>)
                        }</>

                        )}

                    </form>
                </div>
            </div>

        </div>
    )
}

export default ForgotPassword;