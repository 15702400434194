import React, { useEffect, useState } from 'react'
import { RECOMMENDED_JOBS } from '../../Utils/apis';

const useFetchRecommendedJobs = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [jobs, setJobs] = useState([]);


    useEffect(() => {
        (
            async () => {
                if (!loading) {
                    try {
                        setLoading(true)
                        const res = await fetch(RECOMMENDED_JOBS, {
                            method: 'GET',
                            credentials: 'include',
                            headers: {
                                'x-auth-token': window.sessionStorage.getItem('token'),
                            }
                        });

                        if (res.ok) {
                            const jobs = await res.json();
                            setJobs(jobs);
                        }
                        else {
                            const err = await res.json();
                            throw new Error(err.error);
                        }
                    } catch (error) {
                        setError(error.message);
                    }
                    finally {
                        setLoading(false);
                    }
                }
            }
        )();
    }, [])

    return [loading, error, jobs];
}

export default useFetchRecommendedJobs;