import React from 'react';
import HomeIcon from '../../Assets/home_icon.svg';
import { useNavigate } from 'react-router-dom';
import appLogo from '../../Assets/logo.png';

const SideBar = () => {
    const Navigate = useNavigate();
    const list = [
        { text: "Dashboard", icon: HomeIcon, route: '/dashboard' },
        { text: "Jobs", icon: HomeIcon, route: '/jobs' },
        { text: "Discover", icon: HomeIcon, route: '/discover' },
        { text: "CV", icon: HomeIcon, route: '/cv' },
        { text: "Setting", icon: HomeIcon, route: '/settings' }
    ]
    const currentSection = window.location.pathname?.split('/')?.[1];
    return (
        <div className=' px-4 py-6 w-1/6 border-r-2 h-screen'>
            <div className=' flex justify-center'>
                <img
                    className='w-40 cursor-pointer'
                    src={appLogo}
                    alt="appLogo"
                    onClick={()=>Navigate('/')}
                />
            </div>
            <ul className='pt-8'>
                {list.map((item, index) => (
                    <li
                        onClick={() => Navigate(item?.route)}
                        className={`my-2 flex gap-2 cursor-pointer font-semibold hover:bg-gray-100 w-full py-4 px-6  rounded-lg ${'/' + currentSection === item.route && 'bg-gray-100'}`}
                        key={index}
                    >
                        <img
                            className=' w-6'
                            src={item.icon}
                            alt={item.text}
                        />
                        <span className=' w-0 hidden md:w-fit md:inline'>{item.text}</span>
                    </li>))}
            </ul>
        </div>
    )
}

export default SideBar;