import React, { useState } from 'react'
import { useSelector } from 'react-redux';
// import logo from '../Assets/logo.png';
import LoggedOut from './LoggedOut';
import AuthScreen from './AuthScreen';
import LoggedIn from './LoggedIn';
import { GOOGLE_AUTH_API } from '../../Utils/apis';
import { useNavigate } from 'react-router-dom';
import backIcon from '../../Assets/arrow_back.svg';

const BackButton = () => {

  const Navigate = useNavigate();
  const { history } = window;
  return (
    <img
      onClick={() => history.back()}
      className=' w-4'
      src={backIcon}
      alt='backIcon'
    />
  )
}

const Header = ({ text, isButtonNeeded }) => {

  const isUserAuthenticated = useSelector(store => store?.states?.isUserAuthenticated);
  const userName = useSelector(store => store?.user?.email);
  const [isOpenToWork, setIsOpenToWork] = useState(false);
  // const [renderAuthScreen, setRenderAuthScreen] = useState(false);


  return (

    <div className='w-full flex items-center justify-between h-16 border-b-2 px-6 py-4'>
      <div className=' flex gap-2'>
        {isButtonNeeded && <BackButton />}
        <p className=' text-xl font-semibold'>{text}</p>
      </div>
      <div>
        <div data-identity='first'>
          <div
            data-identity='open-to-work'
            className=' flex gap-20 items-center justify-center'
          >
            <div className='flex gap-2 items-center justify-center'>
            <div
              className={` cursor-pointer w-12 h-[18px] flex border-[1px] rounded-full p-[0.14rem] ${isOpenToWork ? ' justify-end' : ' justify-start'}`}
              onClick={() => setIsOpenToWork(pre => !pre)}
            >
              <span
                className={`w-1/2 bg-black rounded-full transition-all ${isOpenToWork ? ' bg-blue-500' : ' bg-black'}`}
              >

              </span>
            </div>
            <p className=' text-xs text-gray-400'>Open for Work</p>
            </div>
            {isUserAuthenticated === false && (<LoggedOut />)}
            {isUserAuthenticated === true && (<LoggedIn />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;