// const user = {
//     "Candidate_Id": "c1253355-jdgt243-sjhdg-254243a",
//     "Name": "Amit Singh",
//     "Email": "amit.singh@gmail.com",
//     "Password": "Asd@9876",
//     "Phone_Number": "9876543210",
//     "Location": "New Delhi",
//     "Handles": {
//         "Linkedin": "https://www.linkedin.com/in/amit-singh",
//         "Twitter": "https://www.twitter.com/amitsingh",
//         "Github": "https://www.github.com/amitsingh",
//         "Other_Handles": ""
//     },
//     "Profile": "Software Developer",
//     "Summary":
//         "Experienced software developer with a strong background in full-stack development.",
//     "Availability": "Immediate",
//     "Education": [
//         {
//             "School": "Kendriya Vidyalaya",
//             "Board": "CBSE",
//             "Marks": "90%",
//             "Year": "2010",
//             "Type": "10th"
//         },
//         {
//             "Board": "CBSE",
//             "Stream": "Science",
//             "Marks": "88%",
//             "Year": "2012",
//             "Type": "12th"
//         },
//         {
//             "College": "Delhi University",
//             "Stream": "Computer Science",
//             "Marks": "85%",
//             "Year": "2016",
//             "Type": "Graduation"
//         },
//         {
//             "College": "IIT Delhi",
//             "Stream": "Computer Science & Engineering",
//             "Marks": "82%",
//             "Year": "2018",
//             "Type": "Post_Graduation"
//         },
//         {
//             "College": "IIT Delhi",
//             "Stream": "Computer Science & Engineering",
//             "Marks": "82%",
//             "Year": "2018",
//             "Type": "Other"
//         }
//     ],

//     "Certification": ["AWS Certified Developer", "Certified Scrum Master"],
//     "Language": ["Hindi", "English"],
//     "Candidate_Skills": ["JavaScript", "Python", "Node.js", "React", "SQL"],
//     "Project": [
//         {
//             "ProjectId": "1",
//             "Project_Name": "E-commerce Platform",
//             "Skill_Used": "React, Node.js",
//             "Summary_Of_Project":
//                 "Developed an end-to-end e-commerce platform with real-time payment integration."
//         },
//         {
//             "ProjectId": "2",
//             "Project_Name": "E-commerce Platform",
//             "Skill_Used": "React, Node.js",
//             "Summary_Of_Project":
//                 "Developed an end-to-end e-commerce platform with real-time payment integration."
//         }
//     ],
//     "Experience": [
//         {
//             "ExperienceId": 1,
//             "Company_Name": "TechSolutions",
//             "Role": "Software Developer",
//             "Starting_Date": "2016-08-01",
//             "End_Date": "2019-12-31",
//             "Professional_Summary":
//                 "Developed web applications for e-commerce and retail industries."
//         },
//         {
//             "ExperienceId": 2,
//             "Company_Name": "InnoTech",
//             "Role": "Senior Software Engineer",
//             "Starting_Date": "2020-01-15",
//             "End_Date": "Present",
//             "Professional_Summary":
//                 "Leading development projects and mentoring junior developers."
//         }
//     ]
// }; 
// export default user;



const user = {
    "Candidate_Id": "c1253355-jdgt243-sjhdg-254243a",
    "Name": "Amit Singh",
    "Email": "amit.singh@gmail.com",
    "Password": "Asd@9876",
    "Phone_Number": "9876543210",
    "Location": "New Delhi",
    "Profile_Link": "https://img.freepik.com/premium-vector/avatar-portrait-kid-caucasian-boy-round-frame-vector-illustration-cartoon-flat-style_551425-43.jpg",
    "About": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a,",
    "Handles": {
        "Linkedin": "https://www.linkedin.com/in/amit-singh",
        "Twitter": "https://www.twitter.com/amitsingh",
        "Github": "https://www.github.com/amitsingh",
        "Other_Handles": ""
    },
    "Profile": "Software Developer",
    "Summary":
        "Experienced software developer with a strong background in full-stack development.",
    "Availability": "Immediate",
    "Education": [
        {
            "Institution": "Kendriya Vidyalaya",
            "Degree": "10th",
            "Marks": "90%",
            "Start_Date": "2010",
            "End_Date": "2010",
        },
        {
            "Institution": "Kendriya Vidyalaya",
            "Degree": "12th, (PCM)",
            "Marks": "88%",
            "Start_Date": "2012",
            "End_Date": "2016"
        },
        {
            "Institution": "Delhi University",
            "Degree": "Graduation",
            "Marks": "85%",
            "Start_Date": "2016",
            "End_Date": "2019"
        },
    ],

    "Certification": ["AWS Certified Developer", "Certified Scrum Master"],
    "Language": ["Hindi", "English"],
    "Candidate_Skills": ["JavaScript", "Python", "Node.js", "React", "SQL"],
    "Project": [
        {
            "ProjectId": "1",
            "Project_Name": "E-commerce Platform",
            "Skill_Used": "React, Node.js",
            "Summary_Of_Project":
                "Developed an end-to-end e-commerce platform with real-time payment integration."
        },
        {
            "ProjectId": "2",
            "Project_Name": "E-commerce Platform",
            "Skill_Used": "React, Node.js",
            "Summary_Of_Project":
                "Developed an end-to-end e-commerce platform with real-time payment integration."
        }
    ],
    "Experience": [
        {
            "ExperienceId": 1,
            "Company_Name": "TechSolutions",
            "Role": "Software Developer",
            "Starting_Date": "2016-08-01",
            "End_Date": "2019-12-31",
            "Professional_Summary":
                "Developed web applications for e-commerce and retail industries."
        },
        {
            "ExperienceId": 2,
            "Company_Name": "InnoTech",
            "Role": "Senior Software Engineer",
            "Starting_Date": "2020-01-15",
            "End_Date": "Present",
            "Professional_Summary":
                "Leading development projects and mentoring junior developers."
        }
    ]
};
export default user;




















































const x = {
    // "Candidate_Id": "c1253355-jdgt243-sjhdg-254243a",
    "Profile": "Software Developer",
}