import React from 'react';
import Header from '../Components/Header/Header'
import { SETTING_SECTION_TEXT } from '../constents/HeaderStrings'
import Profile from '../Components/Profile/Profile';
// import Profile from "../Components/Profile-1/Profile"

const SettingSection = () => {
    return (
        <div>
            <Header text={SETTING_SECTION_TEXT} />
            <div className='p-8 h-[calc(100vh-4rem)] overflow-auto'>
            <Profile />
            </div>
            
        </div>
    )
}

export default SettingSection