import React, { useState } from 'react'
import { UPDATE_CV, UPDATE_PROFILE_IMAGE } from '../../Utils/apis';
import { MAX_PROFILE_RESUME_SIZE } from '../../constents/numbers';

const useCvUploader = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isFileUploaded, setIsFileUploded] = useState(false);

    const fn = async (file) => {
        setError('');
        if (file.size >= MAX_PROFILE_RESUME_SIZE) {
            setError(`File size should be under ${MAX_PROFILE_RESUME_SIZE / 1024}KB`);
        }
        else if (!loading) {
            try {
                setLoading(true)
                const form = new FormData();
                if (file) {
                    form.append("resume", file);
                    form.append("type", "resume");
                }
                const res = await fetch(UPDATE_CV,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'x-auth-token': window.sessionStorage.getItem('token'),
                        },
                        body: form,
                    }
                );

                if (res.ok) {
                    setIsFileUploded(true);
                }
                else {
                    const err = await res.json();
                    throw new Error(err.error);
                }
            } catch (error) {
                setError(error.message);
            }
            finally {
                setLoading(false);
            }
        }
    }

    return [loading, error, isFileUploaded, fn];
}

export default useCvUploader;