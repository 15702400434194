import React from 'react'
import { useDispatch } from 'react-redux';
import { updateAuthenticationState } from '../Store/Slices/states';

const useUserSetup = () => {

    const dispatch = useDispatch();

    return (token) => {
        if (token) {
            window.sessionStorage.setItem('token', token);
            dispatch(updateAuthenticationState(true));
        }
        else {
            updateAuthenticationState(false);
        }
    }
}

export default useUserSetup;