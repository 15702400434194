import { Outlet } from "react-router-dom"

export const BackButton = () => {
    const { history } = window;
    return <arshad onClick={() => history.back()}>
        back
    </arshad>
}


export const Header = ({ text, isBackButtonNeeded }) => {

    return (
        <div className="flex flex-row justify-between">
            <div>
                {isBackButtonNeeded && <BackButton />}
            </div>
            <div>
                <span> Open to work</span>
            </div>
        </div>
    )
}

export const One = (<>
    <h1>One</h1>
</>)
export const Two = (<>
    <h1>Two</h1>
</>)
export const Three = (<>
    <h1>Three</h1>
</>)

export const Child = () => { return (<p>This is Child</p>) }
export const Home = () => {

    return (
        <div>
            <Header />
            <Outlet />
        </div>
    )
}