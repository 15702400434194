import React, { useState } from 'react'
import { UPDATE_PROFILE_IMAGE } from '../../Utils/apis';
import { MAX_PROFILE_IMAGE_SIZE } from '../../constents/numbers';

const useProfileUpdater = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [url, setUrl] = useState('');

    const fn = async (file) => {

        setError('');
        if (file.size >= MAX_PROFILE_IMAGE_SIZE) {
            setError(`File size should be under ${MAX_PROFILE_IMAGE_SIZE / 1024}KB`);
        }
        else if (!loading) {
            try {
                setLoading(true)
                const form = new FormData();
                if (file) {
                    form.append("profilePicture", file);
                    form.append("type", "profilePicture");
                }
                const res = await fetch(UPDATE_PROFILE_IMAGE,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'x-auth-token': window.sessionStorage.getItem('token'),
                        },
                        body: form,
                    }
                );

                if (res.ok) {
                    const url = await res.json();
                    setUrl(url);
                }
                else {
                    const err = await res.json();
                    setError('something went wrong while uploading file')
                }
            } catch (error) {
                setError(error.message);
            }
            finally {
                setLoading(false);
            }
        }
    }

    return [loading, error, url, fn];
}

export default useProfileUpdater;