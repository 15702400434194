import React, { useEffect, useState } from 'react'
import { JOBS_FINDER } from '../Utils/apis';
import { jobQueryFormatter } from '../constents/functions';
import { useDispatch } from 'react-redux';
import { addJobs } from '../Store/Slices/jobs';

const useFetchJobs = (options) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [jobs, setJobs] = useState([]);

    const fn = async (searchQuery) => {
        const query = { title: searchQuery.query, location: searchQuery.locations, type: searchQuery.employmentType, minSalary: searchQuery.minSalary, maxSalary: searchQuery.maxSalary}
        const keyForRedux = jobQueryFormatter(searchQuery);
        const updatedQuery = Object.keys(query)?.map(item => `${item}=${Array.isArray(query[item]) ? query[item]?.join(',') : query[item]}`).join("&");


        if (!loading) {
            try {
                setLoading(true)
                const res = await fetch(JOBS_FINDER + '?' + updatedQuery, {
                    method: 'GET',
                    credentials:'include',
                    headers: {
                        'x-auth-token': window.sessionStorage.getItem('token'),
                    }
                });

                if (res.ok) {
                    const jobs = await res.json();
                    dispatch(addJobs({ query: keyForRedux, jobs: jobs }));

                }
                else {
                    const err = await res.json();
                    throw new Error(err.error);
                }
            } catch (error) {
                setError(error.message);
                return Promise.reject(error.message);
            }
            finally {
                setLoading(false);
            }
        }
    }

    return [loading, error, jobs, fn];
}

export default useFetchJobs;