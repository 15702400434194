import React, { useState } from 'react'
import { USER_EMAIL_REGISTER } from '../../Utils/apis';

const useRegistOtpSender = (onSucess) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fn = async (email) => {

        // this is an email type string;

        if (!loading) {
            try {
                setLoading(true)
                const res = await fetch(USER_EMAIL_REGISTER,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email }),
                    }
                );

                if (res.ok) {
                    onSucess();
                }
                else {
                    const err = await res.json();
                    throw new Error(err.message);
                }
            } catch (error) {
                setError(error.message);
            }
            finally {
                setLoading(false);
            }
            return email;
        }
    }

    return [loading, error, fn];
}

export default useRegistOtpSender;