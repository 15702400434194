import { createSlice } from "@reduxjs/toolkit";

const jobsSlice = createSlice({
    name: 'Jobs',
    initialState: {},
    reducers: {
        addJobs: (state, action) => {
            const { query, jobs } = action.payload;

            if (query in state) {

                // infinite scrool included
                // const list = [...state[query], ...jobs];
                // return { ...state, [query]: list }


                return { ...state, [query]: jobs }
            } else {
                return { ...state, [query]: jobs }
            }
        }
    },
});

export const { addJobs } = jobsSlice.actions;
export default jobsSlice.reducer;