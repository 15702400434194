import { configureStore } from "@reduxjs/toolkit";
import user from './Slices/user';
import states from './Slices/states';
import jobs from './Slices/jobs';

const store = configureStore({
    reducer: {
        user,
        states,
        jobs,
    }
});

export default store;