import React from 'react'
import { GOOGLE_AUTH_API } from '../../Utils/apis';

const useGoogleLogin = () => {

    return async () => {
        try {
            // const res = await fetch(GOOGLE_AUTH_API, {
                //     method: 'GET',
                //     credentials: 'include' // Make sure this is set
                // });
                // const data = await res.json();
                // console.log(data);
                console.log('LogIn with google is working now')
            window.location.href = GOOGLE_AUTH_API;
        } catch (err) {
            console.log(err);
        } finally {
        }
    }
}

export default useGoogleLogin;



// const useGoogleLogin = () => {

//     return async () => {
//         const oauthEndPoint = "https://accounts.google.com/o/oauth2/v2/auth";

//         const form = document.createElement("form");
//         form.setAttribute('method', 'GET');
//         form.setAttribute('action', oauthEndPoint);

//         let param = {
//             client_id: "75955080065-mgpedqp92l0sas7p5cq7o3aadlc5ra22.apps.googleusercontent.com",
//             redirect_uri: "https://zepcruit.ai",
//             // redirect_uri: "http://localhost:3000",
//             response_type: "token",
//             scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
//             include_granted_scopes: "true",
//             state: "pass-through-value"
//         }

//         for (let v in param) {
//             let input = document.createElement('input');
//             input.setAttribute('type', 'hidden');
//             input.setAttribute('name', v);
//             input.setAttribute('value', param[v]);
//             form.appendChild(input);
//         }

//         document.body.appendChild(form);
//         form.submit();
//     }
// }

// export default useGoogleLogin;




// export const signIN = () => {

// }

// export const signOut = () => {
//     const token = JSON.parse(localStorage.getItem('authInfo')).access_token;
//     fetch("https://oauth2.googleapis.com/revoke?token=" + token,
//         {
//             method: 'POST',
//             headers: {
//                 "Content-type": "application/x-www-form-urlencoded"
//             }
//         })
//         .then((data) => {
//             window.location.href = "https://x-tube.netlify.app/";
//         });
// }



// http://localhost:3000/#state=pass-through-value&access_token=
// ya29.a0AeDClZC6dEOp5dCm6bARTvgncO8DdPrhHlZOgH1PvpQZTPThYcxIC2o5989A25vwnQBOTMhxR1gLibpijKZAAiwisX_Qj_L8CkKf0-FLYcfXd3PGlzI26LlqFolNbok42OhhtUNJORgwcNYH2jVFJnuyU0p6Bq88hyJZ8cB4aCgYKAWYSARASFQHGX2Mi-ej0kDB1dHYlXCmmQHjU3w0175

// &token_type=Bearer&

// expires_in=3599&

// scope=email%20profile%20

// https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile%20openid&authuser=0&prompt=consent