import React from 'react'
import { ButtonWithoutIcon, Job_Tag_Box } from '../../constents/components';
import { useNavigate } from 'react-router-dom';

const Job = ({ job }) => {

  const jobTitle = job?.Job_Title ?? "Frontend Developer"
  const companyName = job?.Company_Details?.Company_Name ?? 'Cars24'
  const companyIndustry = job?.Company_Details?.Industry ?? 'Automobile'
  const companyProfileImgUrl = job?.Company_Details?.Company_Profile ?? 'https://img.freepik.com/free-vector/smiling-young-man-illustration_1308-174401.jpg?semt=ais_hybrid';
  const jobDescription = job?.About_Job?.JD?.slice(0, 200) + "..." ?? 'Develop and maintain dynamic, responsive, and user-friendly web applications using HTML, CSS, JavaScript, and modern frameworks React.js';
  const companyLoaction = job?.Location?.[0] ?? "Mumbai";
  const experience = job?.About_Job?.Experience ?? 3;
  const Max_Salary = job?.About_Job?.Min_Salary ?? '3600000'
  const Min_Salary = job?.About_Job?.Max_Salary ?? '1200000'

  const highlights = [job?.About_Job?.Job_Type ?? 'Full Time', `Min. ${experience} yrs of Experience`, `Salary: ${Min_Salary} - ${Max_Salary}`];
  const skills = job?.About_Job?.Skills ?? ["JavaScript", "TypeScript", "React.js"];

  const Navigate = useNavigate();
  const goToJobPage = () => job?.Job_Id && Navigate(`/jobs/id?jid=${job?.Job_Id}`)

  return (
    <div className=' p-8 w-full rounded-2xl'>
      <div className=' flex gap-2 mb-4'>
        <div className=' flex-1'>
          <div className=' flex gap-2 py-2'>
            <img
              className=' rounded-full h-14'
              src={companyProfileImgUrl} alt="" />
            <div className=' flex-1'>
              <h4 className=''>{companyName}</h4>
              <h2
                onClick={() => goToJobPage()}
                className='hover:underline cursor-pointer font-semibold text-xl'
              >{jobTitle}</h2>
            </div>
          </div>
          <p className=' text-sm mt-4'><span className=' font-semibold'>Job Description:</span> {jobDescription}</p>
          <p className=' text-sm my-4'><span className=' font-semibold'>Address:</span> {companyLoaction}</p>
          <Job_Tag_Box text={companyIndustry} />
        </div>
        <div className=' flex-1'>
          <div>
            <p className=' font-semibold text-sm'>Job Highlights</p>
            <div className=' flex gap-2 mt-1 flex-wrap'>{highlights?.map((item, index) => (<Job_Tag_Box text={item} key={index} />))}</div>
          </div>
          <div className=' mt-2'>
            <p className=' font-semibold text-sm'>Required Skills</p>
            <div className=' flex gap-2 mt-1 flex-wrap'>{skills?.map((item, index) => (<Job_Tag_Box text={item} key={index} />))}</div>
          </div>
        </div>
      </div>
      <ButtonWithoutIcon text={'Apply Now'} classNames={'px-6 py-3 mt-3 text-white w-full'} />
    </div>
  )
}

export default Job;