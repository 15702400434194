import React from 'react'
import Header from '../Components/Header/Header'
import { CV_SECTION_TEXT } from '../constents/HeaderStrings'

const CvSection = () => {
    return (
        <div>
            <Header text={CV_SECTION_TEXT} />
            <div className=' h-[calc(100vh-4rem)]'></div>
        </div>
    )
}

export default CvSection