import React from 'react'
import { Outlet } from 'react-router-dom';
import logo from '../../Assets/logo.png';

const Auth = () => {

    return (
        <div className='min-h-screen  w-full flex justify-center gap-12'>
            <div className='flex-1 gap-16 flex-col flex p-32'>
                <img
                    className=' w-44'
                    src={logo}
                    alt={"logo-Icon"}
                />
                <Outlet />
            </div>
            <div className='w-full flex flex-col gap-8 flex-1 bg-[#f8f8f8] p-32'>
            <div className='bg-[#e6e6e6] w-full h-20'></div>
            <div className='bg-[#e6e6e6] w-full h-20'></div>
            <div className='bg-[#e6e6e6] w-full h-2/3 mt-4'></div>
                <div className='bg-[#e6e6e6] w-full h-2/3'></div>
            </div>
        </div>
    )
}

export default Auth;