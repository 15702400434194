import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import useGoogleLogin from '../../Hooks/Auth/useGoogleLogin';
import useUrlBeautifier from '../../Hooks/Auth/useUrlButifire';
import googleIcon from '../../Assets/SocialIcons/Google_Icons.webp';
import { GOOGLE_AUTH_API } from '../../Utils/apis';

const LoggedOut = () => {
    const Navigate = useNavigate();
    const googleLogin = useGoogleLogin();
    const urlFunction = useUrlBeautifier();


    useEffect(() => {
        urlFunction()
    }, [window.location])
    return (<div className=' flex gap-2'>
        <button onClick={() => Navigate('/auth/login')} className=' px-4 rounded-md bg-primary_400 font-semibold text-lg'> LogIn</button>
        <img
            src={googleIcon}
            alt="Login with Google"
            onClick={() => googleLogin()}
            className=' w-8 cursor-pointer'
        />

    </div>
    )
}

export default LoggedOut;