import React, { useState } from 'react'
import { AuthStepsHeading, AuthStepsParagraph, ButtonWithIcon, ButtonWithoutIcon, InputBoxWraper } from '../../../constents/components'
import arrowIcon from '../../../Assets/arrow_back.svg';
import useCreateProfile from '../../../Hooks/Registration/useCreateProfile';
import close from '../../../Assets/close_Icon.svg'

const IntrestUpdate = ({ updateFunctions, sendToNextStep, details, loading }) => {

    const { updateInterest } = updateFunctions;
    const [interest, setInterest] = useState([]);
    const [value, setValue] = useState('');
    const [load, err, isUserProfileCreated] = useCreateProfile()

    const valueChangeHandler = (e) => {
        if (e.key === "Enter") {
            const text = e.target.value;
            if (text.length > 0 && details.interest.length < 5 && !details.interest.includes(text)) {
                updateInterest( [...details.interest, e.target.value] );
                setValue("");
            }

        }
    }
    const remover = (index) => {
        console.log();
        const list = details.interest.filter(item => item !== details.interest[index])
        updateInterest( list);
    };
    return (
        <div className='flex gap-4 flex-col'>
            <h2 className=' my-2 text-black'>Select your Areas of Interest</h2>
            <InputBoxWraper classnames={"w-full"}>
                <input
                    className=' bg-transparent w-full outline-none border-none'
                    type="text"
                    placeholder='Search'
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyUp={(e) => valueChangeHandler(e)}
                />
            </InputBoxWraper>
            <div className=' flex gap-4 flex-wrap'>
                {
                    details.interest?.map((item, index) => (
                        <InputBoxWraper key={index} classnames={"w-fit flex gap-2 items-center"}>
                            <>
                                <img
                                    className=' w-4 cursor-pointer'
                                    src={close}
                                    alt={"close"}
                                    onClick={() => remover(index)}
                                />
                                {item}
                            </>
                        </InputBoxWraper>))
                }
            </div>
            {!loading && !isUserProfileCreated && (<ButtonWithIcon onclick={sendToNextStep} text={"Continue"} icon={arrowIcon} iconClassnames={"rotate-180 h-4"} classNames={" py-4 text-white"} />)}
            {loading && <ButtonWithoutIcon text={"Submitting..."} classNames={" py-2 text-white"} onclick={sendToNextStep} />}

        </div>
    )
}

export default IntrestUpdate;