import React from 'react'
import AppliedJob from '../Components/Jobs/AppliedJob'
const JobSectionChild = () => {
    const appliedJob = ['', '', ''];
    return (
        <div className=' p-4 flex justify-center'>
            <div className=' w-2/3 '>
                <h1>Applied Jobs</h1>
                <div className=' mt-2 border-[1px] border-gray-200 rounded-md overflow-hidden'>
                    {/* {loading && (<p className=''>loading...</p>)} */}
                    {appliedJob?.map((item, index) => (<AppliedJob key={index} job={item} />))}
                </div>
            </div>

        </div>
    )
}

export default JobSectionChild