import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Error from "../Components/Error";
import { Provider } from "react-redux";
import store from "../Store/store";
import AuthContextProvider from "../Contexts/authContext";
import Register from "../Components/Auth/Register";
// import Profile from "../Components/Profile-1/Profile";
import Profile from "../Components/Profile/Profile";
import Login from "../Components/Auth/Login";
import Auth from "../Components/Auth/Auth";
import Protection from "../Components/Auth/Protection";
import ForgotPassword from "../Components/Auth/ForgotPassword";
import ResetPassword from "../Components/Auth/ResetPassword";
import AuthChild from "../Components/Auth/AuthChild";

import JobSectoin from "../Sections/JobSectoin";
import CvSection from "../Sections/CvSection";
import DashboardSection from "../Sections/DashbordSection";
import DiscoverSection from "../Sections/DiscoverSection";
import SettingSection from "../Sections/SettingSection";
import FullJobDetails from "../Components/Jobs/FullJobDetails";
import JobSectionChild from "../Sections/JobSectionChild";

const Homepage = lazy(() => import('../Pages/Homepage'));
const InterviewPage = lazy(() => import('../Pages/InterviewPage'));
// import Homepage from "../Pages/Homepage";

export const sidebarDisabledRoutes = ['', 'auth', 'interview'];

const routes = createBrowserRouter([{
    path: '/',
    element: <Provider store={store}><App /></Provider>,
    errorElement: <Error />,
    children: [
        {
            path: '/',
            element: <Homepage />
        },
        {
            path: '/auth',
            element: <Auth />,
            children: [
                {
                    path: '/auth/',
                    element: <AuthChild />,
                }
                ,
                {
                    path: '/auth/register',
                    element: <Register />
                },
                {
                    path: '/auth/login',
                    element: <Login />
                },
                {
                    path: '/auth/forgotpassword',
                    element: <ForgotPassword />
                },
                {
                    path: '/auth/reset-password',
                    element: <ResetPassword />
                },
            ],
        },

        {
            path: '/dashboard',
            element: (<DashboardSection />),
        },
        {
            path: '/jobs',
            element: <JobSectoin />,
            children: [
                {
                    path: '/jobs/',
                    element: <JobSectionChild />
                },
                {
                    path: '/jobs/id',
                    element: <FullJobDetails />
                },
            ]
        },
        {
            path: '/discover',
            children: [],
            element: <DiscoverSection />
        },
        {
            path: '/settings',
            element: <SettingSection />
        },
        {
            path: '/cv',
            children: [],
            element: < CvSection />
        },
        {
            path: '/interview',
            element: <></>
        },
        {
            path: '/test',
            children: [],
            element: <></>
        },
    ]

}], 
);


export default routes;