import React from 'react';
import Header from '../Components/Header/Header'
import { DASHBOARD_SECTION_TEXT } from '../constents/HeaderStrings'
import { useNavigate } from 'react-router-dom';
import Job from '../Components/Discover/Job';
import useFetchRecommendedJobs from '../Hooks/jobs/useFetchRecommendedJobs';
import { useSelector } from 'react-redux';

const DashbordSection = () => {

    const [loading, error, jobs] = useFetchRecommendedJobs();
    const user = useSelector((store) => store?.user);
    const userName = user.FirstName;



    const Navigate = useNavigate();
    const RecommendedJobs = jobs;
    {
        return (
            <div>
                <Header text={DASHBOARD_SECTION_TEXT} />
                <div className=' p-10 h-[calc(100vh-4rem)]'>
                    <div className=' pb-6'>
                        {/* <img src="" alt="" /> */}
                        <h1 className=' text-xl font-semibold'>Welcome, {userName}</h1>
                    </div>
                    <div className=' flex gap-2'>
                        <div className='flex-1'>
                            {/* Recommended Jobs */}
                            <div className=' rounded-2xl overflow-hidden border-[1px]'>
                                <div className=' p-6 flex justify-between'>
                                    <h2>Recommended Jobs</h2>
                                    <p className=' cursor-pointer' onClick={() => Navigate('/discover')}>See All {RecommendedJobs.length} </p>
                                </div>
                                <hr />
                                {RecommendedJobs.splice(0,1).map((item, index) => (<Job job={item} key={index} />))}
                            </div>

                            {/* DISC Section */}
                            <div className=' mt-8 rounded-md overflow-hidden border-[1px] p-6 flex'>
                                <div className=' w-1/4 p-6 flex flex-col gap-4 items-center border-r-[1px]'>
                                    <h1>Overall DISC Score</h1>
                                    <div className=' w-full bg-gray-200 rounded-full aspect-square'>

                                    </div>
                                </div>
                                <div className=' w-3/4 p-4'>
                                    <h2>{'Score Summary'}</h2>
                                    <div className='mt-4 w-full bg-gray-200 h-6 rounded-sm'></div>
                                    <div className='mt-4 w-[85%] bg-gray-200 h-6 rounded-sm'></div>
                                    <div className='mt-8 w-[50%] bg-gray-200 h-6 rounded-sm'></div>
                                    <div className='mt-4 w-[30%] bg-gray-200 h-6 rounded-sm'></div>
                                </div>

                            </div>
                        </div>
                        <div className=' w-72'></div>
                    </div>

                </div>
            </div>
        )
    }
}

export default DashbordSection