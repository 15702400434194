import { ThreeDots } from 'react-loader-spinner'
import { useRef, useState } from "react";
import { BACKSPACE_KEY_CODE, NINE_NUMBER_KEY_CODE, ZERO_NUMBER_KEY_CODE } from "./keyCodes";


export const Pill = ({ text }) => (<span className=" px-4 py-1 border-[2px] border-primary_800 bg-primary_200 text-black rounded-full">{text}</span>);

export const AuthStepsHeading = ({ text }) => (<h1 className=" pb-2 pt-2 text-[2.4rem] font-">{text}</h1>);
export const AuthStepsParagraph = ({ text }) => (<p className='text-gray-500'>{text}</p>);

export const ButtonWithoutIcon = ({ text, onclick = () => { }, classNames = '' }) => (
    <button
        className={` bg-black rounded-md ${classNames}`}
        onClick={(e) => onclick(e.target)}
    >
        {text}
    </button>
)
export const ButtonWithIcon = ({ text, onclick = () => { }, classNames = '', icon, iconClassnames = '' }) => (
    <button
        className={` flex gap-2 items-center justify-center bg-black rounded-md ${classNames}`}
        onClick={(e) => onclick(e.target)}
    >
        {text}
        {icon && (<img src={icon} className={`${iconClassnames}`} />)}

    </button>
)

export const InputBoxWraper = ({ children, classnames }) => {
    return (<div className={`text-sm  p-4 block bg-[#f0f0f0] rounded-md ${classnames}`}>{children}</div>)
};

export const TopBottomBorder = ({ children, classnames = '' }) => (<div className={` border-[#f0f0f0] border-t-[1px] border-b-[1px] ${classnames}`}>{children}</div>)

export const OTP_Filler = ({ state = [], length = 0, click = () => { }, setState = () => { } }) => {

    console.log(state, length, setState)

    const [currentLastIndex, setCurrentLastIndex] = useState(-1);
    const ref = useRef();

    const keyDown = (index, event) => {
        let lastIndex = currentLastIndex; // last active index
        const keyCode = event.keyCode;


        if (keyCode >= ZERO_NUMBER_KEY_CODE && keyCode <= NINE_NUMBER_KEY_CODE && lastIndex < length - 1) {


            const current = lastIndex + 1;
            // Now cuurent is index which need to fill

            // updating new value at current index
            setState((pre) => {
                const arr = [...pre];
                arr[current] = event.key;
                return arr;
            });

            // removing focus from current filed input
            ref.current.children?.[current]?.blur();

            // activating next input 
            ref.current?.children?.[current + 1]?.select();

            // setting current index for next iteration 
            setCurrentLastIndex(current);
        }

        if (keyCode === BACKSPACE_KEY_CODE && currentLastIndex > -1) {
            let currentIndex = lastIndex;
            // this in now updateable index 


            // removing item from current activated index
            const list = [...state];
            list[currentIndex] = '';
            setState(list);

            // removing focus from current index
            ref.current.children?.[currentIndex]?.blur();
            currentIndex -= 1;

            // adding focus to new activated index
            ref.current?.children?.[currentIndex]?.select();
            setCurrentLastIndex(currentIndex)
        }
    }
    return (<div className=" flex gap-2 my-2" ref={ref}>
        {state?.map((item, index) => (
            <input
                key={index}
                type="text"
                maxLength={1}
                value={item}
                className="px-3 py-0 border-[1px] outline-none rounded-md  w-12 h-12 bg-gray-200"
                onKeyDown={(e) => keyDown(index, e)}
                onChange={() => { }}
            />))}
    </div>)
}

export const Job_Tag_Box = ({ text }) => (<span className=' p-3 bg-gray-100 rounded-sm text-xs text-gray-700'>{text}</span>);


export const Loader = () => {

    // return (<div className=' w-full flex justify-center'>
    //     <p className=' font-semibold'>{loadingtext}</p>
    // </div>)


    return (<div className=' w-full flex justify-center'>
        <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#000000"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
        />
        </div >
    )
}