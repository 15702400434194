import React, { useState } from 'react'
import { REGISTRATION_OTP_VERIFY } from '../../Utils/apis';


const useRegistOtpVerify = (onSucess) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isSucess, setIsSucess] = useState(false);
    console.log(error);
    

    const fn = async (info) => {

        // info object should be contain { email, otp, password}, all details are mandatory

        if (!loading) {
            try {
                setLoading(true)
                const res = await fetch(REGISTRATION_OTP_VERIFY,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(info),
                    }
                );
console.log(res);

                if (res.ok) {
                    const user = await res.json();
                    sessionStorage.setItem("token", user.token)
                    console.log(user);
                    
                    onSucess();
                }
                else {
                    const err = await res.json();
                    console.log(err.message)

                    setError(err.message);
                }
            } catch (error) {
                setError(error.message);
                // return Promise.reject(error.message);
            }
            finally {
                setLoading(false);
            }

            // return Promise.resolve('');
            return info;
        }
    }

    return [loading, error, isSucess, fn];
}

export default useRegistOtpVerify;