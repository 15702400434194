import { Outlet } from "react-router-dom";
import Header from "./Components/Header/Header";
import { useEffect, useState } from "react";
import useAuthValidation from "./Hooks/useAuthValidation";
import SideBar from "./Components/Sidebar/SideBar";
import { sidebarDisabledRoutes } from "./Utils/routes";

function App() {

  useAuthValidation()();

  // const currentSection = window?.location?.pathname.split('/')?.[1];
  const [current, setCurrent] = useState(window?.location?.pathname.split('/')?.[1])

  useEffect(() => {
    setCurrent(window?.location?.pathname.split('/')?.[1]);
  }, [window.location.pathname, current]);
  return (
    <div className="App flex">
      {(!sidebarDisabledRoutes.includes(current)) && (<SideBar />)}
      <div className=" flex-1">
        <Outlet />
      </div>
    </div>
  );
}

export default App;