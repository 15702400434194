import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { JOBS_BY_JOBID } from '../../Utils/apis';

const useFetchJob = (jobId) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [job, setJob] = useState([]);

    useEffect(() => {

        (async () => {
            if (!loading) {
                try {
                    setLoading(true)
                    const res = await fetch(JOBS_BY_JOBID + '?id=' + jobId, {
                        method: 'GET',
                        headers: {
                            'x-auth-token': window.sessionStorage.getItem('token'),
                        }
                    });

                    if (res.ok) {
                        const job = await res.json();
                        setJob(job?.[0]);
                        // dispatch(addJobs({ query: keyForRedux, jobs: jobs }));
                    }
                    else {
                        const err = await res.json();
                        throw new Error(err.error);
                    }
                } catch (error) {
                    setError(error.message);
                }
                finally {
                    setLoading(false);
                }
            }
        })();

    }, [jobId]);

    return [loading, error, job];
}

export default useFetchJob;