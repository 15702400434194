import React, { useState } from 'react'
import { AuthStepsHeading, AuthStepsParagraph } from '../../../constents/components';
import Email from '../Email';
import Password from '../Password';
import { useNavigate } from 'react-router-dom';
import useRegister from '../../../Hooks/Registration/useRegister';
import useRegistOtpSender from '../../../Hooks/Registration/useRegistOtpSender';
import useGoogleLogin from '../../../Hooks/Auth/useGoogleLogin';

const RegistorMain = ({ updatePassword, updateEmail, details, sendToNextStep }) => {

    const heading = 'Let\'s get you started';
    const paragraph = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, similique sunt ipsam esse qui a ullam recusandae consequuntur.";
    const signUpWithGoogle = useGoogleLogin();

    const [isEmailValid, setEmailValid] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [loading, error, otpSender] = useRegistOtpSender(sendToNextStep);

    const Navigate = useNavigate();
    return (
        <div>
            <AuthStepsHeading text={heading} />
            <AuthStepsParagraph text={paragraph} />
            <button
                className='w-full bg-[#f0f0f0] my-4 p-4 text-gray-500 rounded-md outline-none border-none'
                onClick={() => signUpWithGoogle()}
            >
                Sign up with google
            </button>
            <hr className='' />
            <div className=' flex flex-col gap-4 mt-8'>
                <Email email={details?.email} setEmail={updateEmail} setEmailValid={setEmailValid} />
                <Password password={details?.password} setPassword={updatePassword} setPasswordError={setPasswordError} />
            </div>

            {error && (<p className=' text-sm text-primary_700'>{error}</p>)}
            <button
                onClick={() => !loading && otpSender(details?.email)}
                className={`w-full bg-[#000000] text-white my-4 p-4 rounded-md outline-none border-none ${loading ? ' cursor-not-allowed' : 'cursor-pointer'}`}
            >
                {loading ? "loading..." : "Sign up"}
            </button>

            <div className=' mt-6'>
            <p className=' text-gray-400'>Already have an account ? <span className='text-black cursor-pointer'  onClick={() => Navigate('/auth/login')}>Log in </span> </p>
            <p className='pt-1 text-gray-400 text-xs'>By continuing, you agree to Termns of Use and Privacy Policy  </p>
            </div>
        </div>
    )
}

export default RegistorMain;