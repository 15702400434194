import React from 'react'
import { ButtonWithoutIcon, InputBoxWraper, Loader } from '../../constents/components';
import useFetchProfile from '../../Hooks/Registration/useFetchProfile';
import { useSelector } from 'react-redux';
import useCvUploader from '../../Hooks/Registration/useCvUploader';
import { PROFILE_URL } from '../../constents/placeholder';

const Profile = () => {

    const user = useSelector((store) => store?.user);
    const profileUrl = user?.profilePicture || PROFILE_URL
    const firstName = user?.FirstName;
    const lastName = user?.LastName;
    const gender = user?.Gender;
    const designation = user?.Designation;
    const city = user?.Location;
    const country = user?.Country;
    const cvUrl = user?.resumeURL;
    const linkedin = user?.handles?.Linkedin;
    const intrest = user?.Interest;
    const expectedCTC = user?.expectedCTC;
    const currentCTC = user?.currentCTC;

    const [loading, error] = useFetchProfile();
    const [resumeLoading, resumeError, isFileUploaded, cvUploaderFunction] = useCvUploader();

    if(loading){
        return <Loader />
    }
    return (<>
        {user && (<div className=' flex justify-center w-full'>
            <div className=' w-[36rem] flex flex-col gap-2 bg-[1px]'>
                <div className=' flex justify-between'>
                    <h1 className=' text-xl font-semibold'>Profile</h1>
                    <ButtonWithoutIcon text={'Edit'} classNames='px-6 py-1 text-white' />

                </div>
                <div className=' flex items-start'>
                    <img src={profileUrl}
                        className=' w-24 h-24 rounded-full'
                        alt="user profile image" />
                </div>
                <div className=' flex gap-4'>
                    <InputBoxWraper classnames={'flex-1'}>
                        <p>{firstName}</p>
                    </InputBoxWraper>
                    <InputBoxWraper classnames={'flex-1'}>
                        <p>{lastName}</p>
                    </InputBoxWraper>
                </div>
                <div className=' flex gap-4 mt-2'>
                    <InputBoxWraper classnames={'flex-1'}>
                        <p>{gender}</p>
                    </InputBoxWraper>
                </div>

                <h1 className='mt-4 text-xl font-semibold'>Your Location</h1>
                <InputBoxWraper classnames={'w-full'}>
                    <p>{city}</p>
                </InputBoxWraper>
                <InputBoxWraper classnames={'w-full'}>
                    <p>{country}</p>
                </InputBoxWraper>
                <h1 className='mt-4 text-xl font-semibold'>Professional Details</h1>
                <InputBoxWraper classnames={'flex-1'}>
                    <p>{designation}</p>
                </InputBoxWraper>
                <InputBoxWraper classnames={'flex-1'}>
                    <p>{currentCTC}</p>
                </InputBoxWraper>
                <InputBoxWraper classnames={'flex-1'}>
                    <p>{expectedCTC}</p>
                </InputBoxWraper>
                <hr className='mt-4' />

                <h1 className='mt-4 text-xl font-semibold'>Setup your CV</h1>
                <InputBoxWraper classnames={'w-fit'}>
                    <span onClick={() => window.open(cvUrl, '_blank')}>Your CV</span>
                </InputBoxWraper>
                <InputBoxWraper classnames={'w-full'}>
                    <a href={linkedin} target='_blank'>Linkedin</a>
                </InputBoxWraper>
                <hr className='mt-4' />
                <h1 className='mt-4 text-xl font-semibold'>Area of Intrest</h1>
                <div className=' flex flex-wrap gap-4'>
                    {
                        intrest?.map((item, index) => (<InputBoxWraper key={index}>{item}</InputBoxWraper>))
                    }
                </div>
{/* 
                <input type="file" onChange={(e) => cvUploaderFunction(e.target.files[0])} />

                {resumeError && (<p className=' text-red-500'>{resumeError}</p>)}
                {resumeLoading && (<p className=' text-black'>loading...</p>)} */}
            </div>
        </div>)}
    </>)
}

export default Profile;