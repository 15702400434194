import React from 'react'
import Header from '../Components/Header/Header'
import { JOB_SECTION_TEXT } from '../constents/HeaderStrings'
import JobPage from '../Pages/JobPage'
import MainSearch from '../Components/Discover/MainSearch'
import { Outlet } from 'react-router-dom'


const JobSectoin = () => {

    return (
        <div>
            <Header text={JOB_SECTION_TEXT} />
            <div className=' h-[calc(100vh-4rem)] overflow-auto'>
                <Outlet />
            </div>
        </div>
    )
}

export default JobSectoin;