import React from 'react'

const Filters = ({ filters, setFilters }) => {
    return (
        <div className=' pt-6 pl-6 border-l-[2px] border-t-[1px] border-gray-300 h-full w-1/4    '>
            <p className=' text-xl'>Filters</p>
        </div>
    )
}

export default Filters;






// const defaultJoBType = "All";
// const defaultLocation = "Chose";
// const [query, setQuery] = useState('');
// const [suggestions, setSuggestions] = useState([]);
// const [focusedIndex, setFocusedIndex] = useState(1);

// const onKeyupdown = (e) => {
//     switch (e.keyCode) {
//         case ARROW_UP_KEY_CODE: {
//             // setFocusedIndex(pre => { if (pre > 0) { return pre -= 1 } });
//             setFocusedIndex(pre => pre -= 1);
//         };
//             break;
//         case ARROW_DOWN_KEY_CODE: { };
//             setFocusedIndex(pre => pre += 1);
//             break;
//         case ENTER_KEY_CODE: {
//             setQuery(suggestions[focusedIndex]);
//             setShowSuggestions(false);
//         };
//         case TAB_KEY_CODE: {
//             setQuery(suggestions[focusedIndex]);
//         };
//             break;
//         default: { };
//     }
// }
// useEffect(() => setSuggestions(jobTitles?.filter(item => item.includes(query))), [query]);

// const locationHandler = (value) => {

//     if (value === defaultLocation) {
//         setSearchQuery((pre) => { return { ...pre, locations: [] } });
//     }
//     else if (searchQuery.locations?.includes(value)) {
//         // setSearchQuery((pre) => { return { ...pre} });
//     }
//     else {
//         setSearchQuery(pre => { return { ...pre, locations: [...pre.locations, value] } });
//     }
// }

{/* <div className=' flex gap-4 mt-2'>
    <select className=' px-2 py-1 outline-none border-none' onChange={(e) => { setSearchQuery(pre => { return e.target.value === defaultJoBType ? { ...pre, employmentType: "" } : { ...pre, employmentType: e.target.value } }) }}>
        {[defaultJoBType, ...JOB_TYPE_LIST]?.map((item, index) => (<option key={index}>{item}</option>))}
    </select>
    <select className=' px-2 py-1 outline-none border-none' onChange={(e) => locationHandler(e.target.value)}>
        {[defaultLocation, ...locations]?.map((item, index) => (<option key={index}>{item}</option>))}
    </select>
</div> */}