import React from 'react'
import { AuthStepsHeading, AuthStepsParagraph, ButtonWithIcon, InputBoxWraper } from '../../../constents/components';
import arrowIcon from '../../../Assets/arrow_back.svg';

const Designation = ({ details, sendToNextStep, updateFunctions }) => {

    const requirementSummary = 'A bit more about your professional life..';
    const placeholders = {
        designation: 'Your Current Designation',
        currentCtc: 'Current CTC',
        expectedCtc: 'Expected CTC'
    }

    const { updateDesignation, updateExpectedCTC, updateCurrentCTC } = updateFunctions;
    return (
        <div className=' flex gap-2 flex-col'>
            <div className='my-5 flex flex-col gap-3'>
                <p>{requirementSummary}</p>
                <InputBoxWraper>
                    <input
                        type="text"
                        className=' bg-transparent outline-none border-none w-full'
                        placeholder={placeholders.designation}
                        value={details.designation}
                        onChange={(e) => updateDesignation(e.target.value)}
                    />
                </InputBoxWraper>
                <InputBoxWraper>
                    <input
                        type="number"
                        className=' bg-transparent outline-none border-none w-full'
                        placeholder={placeholders.currentCtc}
                        value={details.currentCtc}
                        onChange={(e) => updateCurrentCTC(e.target.value)}
                    />
                </InputBoxWraper>
                <hr/>
                <InputBoxWraper>
                    <input
                        type="number"
                        className=' bg-transparent outline-none border-none w-full'
                        placeholder={placeholders.expectedCtc}
                        value={details.expectedCtc}
                        onChange={(e) => updateExpectedCTC(e.target.value)}
                    />
                </InputBoxWraper>
            </div>

            <ButtonWithIcon onclick={sendToNextStep} text={"Continue"} icon={arrowIcon} classNames={'p-4 text-white'} iconClassnames={"rotate-180 h-4"} />
        </div>
    )
}

export default Designation;