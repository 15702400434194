import React from 'react'
import { Job_Tag_Box } from '../../constents/components';
import { useNavigate } from 'react-router-dom';

const AppliedJob = ({ job }) => {
    const Navigate = useNavigate();
    const jobTitle = job?.Job_Title ?? 'Software';
    const currentStatus = job?.Status ?? 'In Review';
    const appliedTime = job?.Status ?? 'Applied 5 days ago';
    const companyName = job?.Company_Details?.Company_Name ?? 'Factacy'
    const companyIndustry = job?.Company_Details?.Industry ?? ' Information Technology';
    const companyProfileImgUrl = job?.Company_Details?.Company_Profile ?? 'https://img.freepik.com/free-vector/smiling-young-man-illustration_1308-174401.jpg?semt=ais_hybrid';
    const jobDescription = job?.About_Job?.JD?.slice(0, 100) ?? 'We are seeking a talented and passionate Frontend Developer to join our team. The ideal candidate will have strong expertise in modern JavaScript frameworks like React.js, a keen eye for design and usability, and the ability to translate user requirements into responsive, high-performance web applications.';
    const companyLoaction = job?.Location?.[0] ?? 'Gurugram'
    const experience = job?.About_Job?.Experience ?? '3'
    const Max_Salary = job?.About_Job?.Min_Salary ?? '200,000'
    const Min_Salary = job?.About_Job?.Max_Salary ?? '40,000'

    const highlights = [job?.About_Job?.Job_Type ?? 'Full Time', `Min. ${experience} yrs of Experience`, `Salary: ${Min_Salary} - ${Max_Salary}`];

    return (
        <div className=' border-[1px] p-8 w-full' >
            <div className=' flex gap-2 mb-4'>
                <div className=' flex-1'>
                    <div className=' flex gap-2 py-2'>
                        <img
                            onClick={() => Navigate('id?jid=abc')}
                            className=' rounded-full h-14'
                            src={companyProfileImgUrl} alt="" />
                        <div className=' flex-1'>
                            <h4 className=''>{companyName}</h4>
                            <h2 className=' font-semibold text-xl'>{jobTitle}</h2>
                        </div>
                    </div>
                    <p className=' text-sm'><span className=' font-semibold'>Job Description:</span> {jobDescription}</p>
                    <p className=' text-sm mb-4'><span className=' font-semibold'>Address:</span> {companyLoaction}</p>
                    <Job_Tag_Box text={companyIndustry} />
                </div>
                <div className=' flex-1'>
                    <div className=' flex gap-2 justify-end items-center'>
                        <span className=' rounded-md bg-gray-200 px-4 py-2'>{currentStatus}</span>
                        <p className=' text-sm'>{appliedTime}</p>
                    </div>
                    <div>
                        <p className=' font-semibold text-sm'>Job Highlights</p>
                        <div className=' flex gap-2 mt-1 flex-wrap'>{highlights?.map((item, index) => (<Job_Tag_Box text={item} key={index} />))}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppliedJob;