const domain = 'https://zepcruit.ai';

// ----Fake APIs
export const FAKE_API = `https://jsonplaceholder.typicode.com/todos/1`;
export const USER_PROFILE_UPDATE = `https://jsonplaceholder.typicode.com/todos/1`;
// ----Fake APIs

export const USER_LOGIN = `${domain}/api/auth/login`;
export const USER_REGISTER = `${domain}/api/auth/register`;
export const USER_EMAIL_REGISTER = `${domain}/api/auth/send-otp`;
export const USER_PROFILE_GETTER = `${domain}/api/users/get-profile`;
export const REGISTRATION_OTP_VERIFY = `${domain}/api/auth/verify-otp`
export const USER_AUTH_VALIDATION = `${domain}/api/users/verify-token`;
export const JOBS_FINDER = `${domain}/api/users/search-jobs`;
export const RECOMMENDED_JOBS = `${domain}/api/users/homepage`
export const APPLIED_JOBS_FINDER = `${domain}/api/users/fetch-applied-jobs`;
export const REQUEST_FORGOT_PASSWORD = `${domain}/api/auth/forgot-password`;
// export const UPDATE_FORGOT_PASSWORD = `${domain}----------------------------`;
export const UPDATE_FORGOT_PASSWORD = `${domain}/api/auth/reset-password`;
// export const SEND_OTP_TO_VERIFY_EMAIL = `${domain}/api/auth/sendOTP`;
export const USER_REGISTER_OTP_SENDER = `${domain}/api/auth/register`;
export const UPDATE_PROFILE_IMAGE = `${domain}/api/users/profile-picture`;
export const UPDATE_CV = `${domain}/api/users/resume`;
export const CREATE_PROFILE = `${domain}/api/users/create-profile`;
export const JOBS_BY_JOBID = `${domain}/api/users/getjob`;


export const GOOGLE_AUTH_API = `${domain}/api/auth/google`;