import React, { useState } from 'react'
import { ButtonWithIcon, ButtonWithoutIcon, InputBoxWraper } from '../../../constents/components'
import { PROFILE_CV_WHILE_SIGNUP_LABEL_ID } from '../../../constents/htmlids'
import arrowIcon from '../../../Assets/arrow_back.svg';
import useCvUploader from '../../../Hooks/Registration/useCvUploader';
import { MAX_PROFILE_RESUME_SIZE } from '../../../constents/numbers';

const CvStep = ({ updateFunctions, sendToNextStep, details }) => {
  const { updateCv, updateLinkedin } = updateFunctions;
  const [linkedin, setLinkedin] = useState('');
  const [cv, setCv] = useState('');

  const [loading, error, isFileUploaded, cvUploader] = useCvUploader();
  return (
    <div className='flex gap-4 flex-col'>
      <h2 className=' mb-2 font-semibold text-black'>Profile Details</h2>
      <InputBoxWraper classnames={"w-fit text-[#A9A9A9] "}>
        {loading && 'uploading...'}
        {isFileUploaded && 'File Uploaded Successfully'}
        {!loading && !isFileUploaded && (<>
          <label
            className=' cursor-pointer'
            htmlFor={PROFILE_CV_WHILE_SIGNUP_LABEL_ID}>
            Upload Your CV
          </label>
          <input
            className=' hidden'
            id={PROFILE_CV_WHILE_SIGNUP_LABEL_ID}
            type="file"
            onChange={(e) => { setCv(e.target.files[0]); cvUploader(e.target.files[0]) }}
          />
        </>)}
      </InputBoxWraper>
      {error && (<p className=' text-red-500'>{error}</p>)}
      <InputBoxWraper classnames={"w-full"}>
        <input
          className=' bg-transparent w-full outline-none border-none'
          type="text"
          placeholder='Enter your Linkedin URL'
          value={details.linkedin}
          onChange={(e) => updateLinkedin(e.target.value)}
        />
      </InputBoxWraper>
      <ButtonWithIcon onclick={sendToNextStep} text={"Continue"} icon={arrowIcon} iconClassnames={"rotate-180 h-4"} classNames={"py-4 text-white"} />
      <ButtonWithoutIcon onclick={sendToNextStep} text={"Do it Later"} classNames={" bg-transparent text-gray-500"} />
    </div>
  )
}

export default CvStep;