import React, { useReducer, useState } from 'react'
import Email from '../Email';
import { useNavigate } from 'react-router-dom';
import useRegistOtpSender from '../../../Hooks/Registration/useRegistOtpSender';
import { MINIMUM_PASSWORD_LENGTH } from '../../../constents/numbers';
import { PROFILE_IMAGE_WHILE_SIGNUP_LABEL_ID } from '../../../constents/htmlids';
import { GENDERS_LIST } from '../../../constents/list';
import { AuthStepsHeading, AuthStepsParagraph, ButtonWithIcon, InputBoxWraper } from '../../../constents/components';
import arrowIcon from '../../../Assets/arrow_back.svg';
import useProfileUpdater from '../../../Hooks/Registration/useProfileUpdater';


const FirstDetails = ({ details, sendToNextStep, updateFunctions }) => {
    const { updateFirstName, updateLastName, updateGender, updateDesignation, updatecity, updateCountry } = updateFunctions;
    const defaultGenderValue = "Select your Gender";

    const Navigate = useNavigate();
    const [loading, error, isFileUploaded, imageUploader] = useProfileUpdater();
    const names = [
        {
            placeholder: "First Name",
            value: details.firstName,
            change: updateFirstName,
        },
        {
            placeholder: "Last Name",
            value: details.lastName,
            change: updateLastName,
        },
    ]

    const handleGenderChange = (value) => updateGender(defaultGenderValue === value ? '' : value);
    return (
        <div className=' flex gap-2 flex-col'>
            {/* <InputBoxWraper classnames={"w-fit"}>
                {loading && 'uploading...'}
                {isFileUploaded && 'File Uploaded Successfully'}

                {
                    !loading && !isFileUploaded && (<>
                        <label
                            className=' cursor-pointer'
                            htmlFor={PROFILE_IMAGE_WHILE_SIGNUP_LABEL_ID}>
                            Upload profile image
                        </label>
                        <input
                            className=' hidden'
                            id={PROFILE_IMAGE_WHILE_SIGNUP_LABEL_ID}
                            type="file"
                            onChange={(e) => { dispatch({ type: UPDATE_PROFILE_IMAGE, payload: e.target.files[0] }); imageUploader(e.target.files[0]) }}
                        /></>)
                }

            </InputBoxWraper> */}


            {/* first and last name */}
            <div className='my-5 flex flex-col gap-3'>
                <p>Getting to know you..</p>
                <div className=' flex flex-row gap-3'>
                    {names.map((item, index) => (
                        <InputBoxWraper classnames={"flex-1"} key={index}>
                            <input
                                type="text"
                                className=' bg-transparent outline-none border-none w-full'
                                placeholder={item.placeholder}
                                value={item.value}
                                onChange={(e) => item.change(e.target.value)}
                            />
                        </InputBoxWraper>
                    ))}
                </div>

                <InputBoxWraper classnames={"flex-1"}>
                    <select
                        onChange={(e) => handleGenderChange(e.target.value)}
                        placeholder={"Slect your gender"}
                        className=' w-full placeholder:text-slate-100 bg-transparent appearance-none outline-none border-none'
                    >
                        {[defaultGenderValue, ...GENDERS_LIST]?.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                    </select>
                </InputBoxWraper>

            </div>

            <div className='my-5 flex flex-col gap-3'>
                <p>Where are you from?</p>
                <InputBoxWraper>
                    <input
                        type="text"
                        className=' bg-transparent outline-none border-none w-full'
                        placeholder={"Enter your city"}
                        value={details.city}
                        onChange={(e) => updatecity(e.target.value)}
                    />
                </InputBoxWraper>
                <InputBoxWraper>
                    <input
                        type="text"
                        className=' bg-transparent outline-none border-none w-full'
                        placeholder={"Enter your country"}
                        value={details.country}
                        onChange={(e) => updateCountry(e.target.value)}
                    />
                </InputBoxWraper>
            </div>
            <ButtonWithIcon onclick={sendToNextStep} text={"Continue"} icon={arrowIcon} classNames={'p-4 text-white'} iconClassnames={"rotate-180 h-4"} />
        </div>
    )

}

export default FirstDetails;




















































// return (
//     <form
//         onSubmit={(e) => e.preventDefault()}
//         className='p-4 rounded-xl min-w-96'
//         style={{ "boxShadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
//     >
//         <div>
//             <div className='mb-1 flex gap-2 items-end'>
//                 <p className=' font-semibold'>Name</p>
//             </div>
//             <input
//                 className=' py-1 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
//                 type='text'
//                 placeholder='Enter Your Name'
//                 value={values.name}
//                 onChange={(e) => setName(e.target.value)}
//             />
//         </div>
//         <Email
//             email={values.email}
//             setEmail={setEmail}
//             setEmailValid={setEmailValidation}
//         />
//         <div>
//             <div className='mb-1 flex gap-2 items-end'>
//                 <p className=' font-semibold'>Password</p>
//             </div>
//             <input
//                 className=' py-1 px-2 outline-none rounded-md border-[1px] border-gray-400 w-full'
//                 type='password'
//                 placeholder='create your password'
//                 value={values.password}
//                 onChange={(e) => setPassword(e.target.value)}
//             />
//         </div>

//         {errors?.message && (<p className='text-sm mt-2 font-semibold text-[#ff0000]'>{errors?.message}</p>)}
//         {
//             loading ? (<p>loading...</p>) : (
//                 <button
//                     className='mt-2 px-4 py-2 rounded-lg text-white bg-primary_800 hover:bg-primary_500 hover:cursor-pointer'
//                     onClick={onRegisterClick}
//                 >
//                     Register
//                 </button>
//             )
//         }

//         {
//             errors.wrongPasswordCount > 2 && (<p
//                 className='mt-4 text-primary_800 font-semibold cursor-pointer hover:underline'
//                 onClick={() => Navigate('/auth/forgetpassword')}
//             >
//                 Forget password
//             </p>)
//         }

//         <p className='mt-2 text-xs font-semibold text-gray-900'>Existing user
//             <span
//                 className='ml-1 text-primary-800 font-bold cursor-pointer hover:underline'
//                 onClick={() => Navigate('/auth/login')}
//             >log in </span>here
//         </p>
//     </form>
// )