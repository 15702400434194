import React, {useState} from 'react';
import { CREATE_PROFILE } from '../../Utils/apis';

const useCreateProfile = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isUserProfileCreated, setIsUserProfileCreated] = useState(false);

    const fn = async (info) => {

        if (!loading) {
            try {
                setLoading(true)
                const res = await fetch(CREATE_PROFILE,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'x-auth-token': window.sessionStorage.getItem('token'),
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(info),
                    }
                );

                if (res.ok) {
                    // const user = await res.json();
                    // window.localStorage.setItem('token', user.token);
                    setIsUserProfileCreated(true);
                    window.open("/dashboard", "_self")
                }
                else {
                    const err = await res.json();
                    throw new Error(err.error);
                }
            } catch (error) {
                setError(error.message);
            }
            finally {
                setLoading(false);
            }
        }
    }

    return [loading, error, isUserProfileCreated, fn];
}

export default useCreateProfile;