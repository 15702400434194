import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useUserSetup from './useUserSetup';
import { updateAuthenticationState } from '../Store/Slices/states';
import { useNavigate } from 'react-router-dom';


const useLogOut = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const setupAuth = useUserSetup();
    const Navigate = useNavigate();

    const fn = async () => {
        if (!loading) {
            window.sessionStorage.clear();
            dispatch(updateAuthenticationState(false));
            window.open('/auth/login', '_self');
        }
    }

    return [loading, error, fn];
}

export default useLogOut;