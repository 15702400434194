import React, { useState } from 'react'
import { UPDATE_FORGOT_PASSWORD } from '../Utils/apis';

const useUpdatePassword = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [sucess, setSucess] = useState(false);

    const fn = async (password, authId) => {
        if (!loading) {
            try {
                setLoading(true)
                const res = await fetch(UPDATE_FORGOT_PASSWORD + `?id=${authId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ newPassword: password, id: authId }),
                });

                if (res.ok) {
                    setSucess(true);
                }
                else {
                    const err = await res.json();
                    throw new Error(err.error);
                }
            } catch (error) {
                setError(error.message);
                return Promise.reject(error.message);
            }
            finally {
                setLoading(false);
            }
        }
    }

    return [loading, error, sucess, fn];
}

export default useUpdatePassword;