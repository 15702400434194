import React, { useState } from 'react'
import useLogIn from '../../Hooks/useLogIn';
import Email from './Email';
import { useNavigate } from 'react-router-dom';
import { AuthStepsHeading, AuthStepsParagraph, ButtonWithIcon, InputBoxWraper } from '../../constents/components';
import arrowIcon from '../../Assets/arrow_back.svg';
import useGoogleLogin from '../../Hooks/Auth/useGoogleLogin';

const Login = () => {
    const Navigate = useNavigate();
    // const onSucessLogin = ()=>{window.open('/dashboard');};
    const onSucessLogin = () => { window.open('/dashboard', '_self'); };

    const loginWithGoogle = useGoogleLogin(onSucessLogin);

    const invalidUserError = 'invalid user'
    const invalidPasswordError = 'invalid password';

    const valuesInitial = { email: '', password: '' };
    const errorsInitial = { emailTextError: false, wrongPasswordCount: 0, message: '' }
    const [values, setValues] = useState(valuesInitial);
    const [errors, setErrors] = useState(errorsInitial);


    const invalidPassword = () => {
        setValues(pre => { return { ...pre, password: '' } });
        setErrors(pre => { return { ...pre, wrongPasswordCount: pre.wrongPasswordCount + 1 } });
    }
    const invalidUser = () => {
        setValues(valuesInitial);
    }
    const setPassword = (value) => {
        setErrors(pre => { return { ...pre, message: '' } });
        setValues(pre => { return { ...pre, password: value } });
    }
    const setEmail = (value) => {
        setErrors(pre => { return { ...pre, message: '' } });
        setValues(pre => { return { ...pre, email: value } });
    }
    const setEmailValidation = (bool) => {
        setErrors(pre => { return { ...pre, emailTextError: bool } });
    }


    const [loading, error, logInFunction] = useLogIn(onSucessLogin);


    const onLogIn = () => {
        // if (!errors.emailTextError && values.email.length > 0 && values.password.length > 6)
        logInFunction(values.email, values.password)
            .then(() => { })
            .catch((err) => {
                if (err === invalidUserError) {
                    invalidUser();
                } else if (err === invalidPasswordError) {
                    invalidPassword();
                }
                else {
                    setErrors(pre => { return { ...pre, message: 'somthing went worng, try again' } });
                    invalidUser();
                }
            });

    }

    return (
        <div>
            <AuthStepsHeading text="Login to Zepcruit" />
            <AuthStepsParagraph text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, similique sunt ipsam esse qui a ullam recusandae consequuntur." />
            <div className='my-4'>
                    <button
                        className='w-full bg-[#f0f0f0] mb-4 p-4 text-gray-500 rounded-md outline-none border-none'
                        onClick={() => loginWithGoogle()}
                    >
                        Login with Google
                    </button>
                    <hr/>
                </div>
            <form
                onSubmit={(e) => e.preventDefault()}
                className='py-4 rounded-xl flex flex-col gap-4'
            >
                <Email
                    email={values.email}
                    setEmail={setEmail}
                    setEmailValid={setEmailValidation}
                />
                <div>
                    <InputBoxWraper>
                        <input
                            className=' bg-transparent border-none outline-none w-full'
                            type='password'
                            placeholder='Create your password'
                            value={values.password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </InputBoxWraper>
                </div>

                {/* {errors?.message && (<p className='text-sm mt-2 font-semibold text-[#ff0000]'>{errors?.message}</p>)} */}
                {

                        <ButtonWithIcon
                            onclick={onLogIn}
                            icon={arrowIcon}
                            iconClassnames={'rotate-180 w-4'}
                            text={loading ? "Loading" : "Log In"}
                            classNames={'text-white w-full mt-6 p-4'}
                        />
                    
                }
                {/* {
                    loading && (
                        <ButtonWithIcon
                            onclick={onLogIn}
                            icon={arrowIcon}
                            iconClassnames={'rotate-180 w-4'}
                            text={"logging..."}
                            classNames={'text-white w-full mt-4 px-6 py-1'}
                        />
                    )
                } */}

                {/* <p
                className='mt-4 text-sm text-primary_800 font-semibold cursor-pointer hover:underline'
                onClick={() => Navigate('/auth/forgotpassword')}
            >
                Forget password
            </p> */}
                         <div className=' mt-6'>
            <p className=' text-gray-400'>Do not have an account ? <span className='text-black cursor-pointer'  onClick={() => Navigate('/auth/register')}>Register</span> </p>
            <p className='pt-1 text-gray-400 text-xs'>By continuing, you agree to Termns of Use and Privacy Policy  </p>
            </div>
            </form>
        </div>

    )
}

export default Login;
// {
//     wrongAttemptsCount >= 2 && (<p onClick={() => Navigate('/auth/forgetpassword')} className='text-sm mt-1 text-[#ff0000]'>forget password</p>)
// }
// {
//     wrongAttemptsCount < 2 && errors?.password && (<p className='text-sm mt-4 text-[#ff0000]'>{errors?.password}</p>)
// }
// const [wrongAttemptsCount, setWrongAttemptsCount] = useState(0);