import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import useUpdatePassword from '../../Hooks/useUpdatePassword';
import Error from '../Error';
import { UPDATE_FORGOT_PASSWORD } from '../../Utils/apis';

const PasswordComponent = ({ name, id, placeholder, value, change, blur }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [inFocus, setInFocus] = useState(false);

    return (
        <div
            className={` w-full flex rounded-[0.04rem] outline-none px-2 py-1 outline-[#9ca3af] outline-[0.1rem] ${inFocus ? " outline-primaryBlue outline-[0.1rem]" : ""
                } `}
            onBlur={() => setInFocus(false)}
        >
            <input
                className=" flex-1 outline-none border-none"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                name={name}
                id={id}
                placeholder={placeholder}
                onFocus={() => setInFocus(true)}
                onBlur={blur}
                value={value}
                onChange={change}
            />
            {/* <img
                className=" cursor-pointer h-5"
                src={showPassword ? visibility_on : visibility_off}
                alt="visibility_icon"
                onClick={() => setShowPassword((pre) => !pre)}
            /> */}
        </div>
    );
};
const ResetPassword = () => {

    const [queries] = useSearchParams();
    const id = queries.get('id');
    // if (!id) {
    //     return <Error />
    // }

    const [loading, error, success, updateFunction] = useUpdatePassword();
    const [value, setValue] = useState('');

    const submit = async (e) => {
        e.preventDefault();
        try {
            const success = await updateFunction(value, id);
        }
        catch (err) {
            console.log(err);
        }

    }
    return (
        <div className=' h-screen w-screen flex justify-center items-center'>
            <div className=' overflow-y-auto rounded-xl shadow-xl items-center p-8 min-h-[420px] bg-[#fff] border-[1px] border-[#f1f1f1] w-[24rem]'>
                <div className='fadeIncrease'>
                    <h1 className=" text-3xl mb-4 font-bold">
                        <span className=" text-primaryBlue">Reset Password</span>
                    </h1>
                    <form onSubmit={(e) => submit(e)} className="flex flex-col gap-4">
                        <div className=" flex flex-col gap-2 lg:gap-6">
                            <div className=" flex-1 flex flex-col gap-2">
                                <div className=" flex gap-2 items-end">
                                    <p className=" text-md capitalize text-[#000000] font-medium">
                                        Password
                                    </p>
                                </div>
                                <PasswordComponent
                                    name={"password"}
                                    id={"password"}
                                    placeholder={"Enter new password"}
                                    value={value}
                                    change={(e) => setValue(e.target.value)}
                                />
                            </div>

                        </div>
                        {
                            loading ? (<span>updating...</span>) : (<> {
                                success ? (<span>{UPDATE_FORGOT_PASSWORD}</span>) : (
                                    <button
                                        type="submit"
                                        className={`px-12 font-semibold py-2 border rounded-full bg-buttonCTA cursor-pointer ${value.length < 8 && ' cursor-not-allowed'}`}
                                        disabled={value.length > 8 ? false : true}
                                    >
                                        Update Password
                                    </button>)
                            }
                            </>)

                        }
                    </form>
                </div>
            </div>

        </div>
    )
}

export default ResetPassword;