import React, { useEffect, useRef, useState } from 'react'
import { BACKSPACE_KEY_CODE, NINE_NUMBER_KEY_CODE, ZERO_NUMBER_KEY_CODE } from '../../../constents/keyCodes';
import { OTP_LENGTH, RESEND_OTP_DELAY_IN_SECONDS, RESEND_OTP_LIMITS } from '../../../constents/numbers';
import useRegistOtpVerify from '../../../Hooks/Registration/useRegistOtpVerify';
import useRegistOtpSender from '../../../Hooks/Registration/useRegistOtpSender';
import { AuthStepsHeading, AuthStepsParagraph, OTP_Filler } from '../../../constents/components';
import { useNavigate } from 'react-router-dom';

const OtpComponent = ({ details, sendToNextStep }) => {
    const { email, password } = details;
    const [resendOtpLoading, resendOtpError, isSucess, otpSendingFunction] = useRegistOtpSender();
    const [otpVerifyLoading, otpVerifyError, isOtpVerified, otpVerifier] = useRegistOtpVerify(sendToNextStep);
    const Navigate = useNavigate()

    const [otp, setOtp] = useState(new Array(6).fill(''));
    console.log(otpVerifyError);
    
    // { isOtpVerified && sendToNextStep() }

    const [secondsLeft, setSecondsLeft] = useState(RESEND_OTP_DELAY_IN_SECONDS);
    const [isResendButtonEnable, setIsResendButtonEnable] = useState(false);

    const intervalId = useRef();
    const secondsTimerCreater = () => {

        intervalId.current = setInterval(() => {

            setSecondsLeft(pre => {
                if (pre === 0) {
                    setIsResendButtonEnable(true);
                    clearInterval(intervalId.current);
                    return pre;
                }
                else {
                    return pre - 1;
                }
            });

        }, 1000);
    }
    useEffect(() => secondsTimerCreater(), []);


    const onSubmit = () => otpVerifier({ email, password, otp: otp?.map(item => parseInt(item)).reduce((accumulator, currentvalue) => { return (accumulator * 10) + currentvalue }) });
    const otpResender = () => otpSendingFunction(details.email);
    return (
        <form
            onSubmit={(e) => e.preventDefault()}
            className='flex flex-col  rounded-xl min-w-96'
        >
            <div className='mt-4'>

                <OTP_Filler
                    length={6}
                    state={otp}
                    setState={setOtp}
                />
            </div>

            {otpVerifyError && (<p className='text-sm mt-2 font-semibold text-[#ff0000]'>{otpVerifyError}</p>)}

            {/* <div className=' flex justify-between'>
                {secondsLeft > 0 && (<p>{secondsLeft}</p>)}
                {isResendButtonEnable &&
                    (<span
                        className={` text-sm ${isResendButtonEnable ? ' text-primary_800 hover:cursor-pointer' : ' text-primary_500 hover:cursor-not-allowed'}  hover:text-primary_500 `}
                        onClick={() => isResendButtonEnable && otpResender()}
                    >
                        Resend OTP
                    </span>
                    )
                }
            </div> */}

            {
                    <button
                        className={`mt-8 w-full p-4 rounded-lg text-white ${otp.length < OTP_LENGTH ? ' bg-gray-500 hover:cursor-not-allowed' : ' bg-black hover:cursor-pointer'}  hover:bg-gray-800 `}
                        onClick={() => otp.length === OTP_LENGTH && onSubmit()}
                    >
                      {otpVerifyLoading ? "Loading..." :  "Submit"}
                    </button>
                
            }

<div className=' mt-6'>
            <p className=' text-gray-400'>Already have an account ? <span className='text-black cursor-pointer'  onClick={() => Navigate('/auth/login')}>Log in </span> </p>
            <p className='pt-1 text-gray-400 text-xs'>By continuing, you agree to Termns of Use and Privacy Policy  </p>
            </div>
        </form>
    )
}

export default OtpComponent;






// const [resendOtpCounts, setResendOtpCounts] = useState(0);

// const keyDown = (e) => {
//     if (e.keyCode >= ZERO_NUMBER_KEY_CODE && e.keyCode <= NINE_NUMBER_KEY_CODE) {
//         setOtp((pre) => pre.length >= OTP_LENGTH ? pre : pre + e.key);
//     }
//     if (e.keyCode === BACKSPACE_KEY_CODE) {
//         setOtp((pre) => pre.slice(0, pre.length - 1));
//     }
// }



// const otpResender = () => {
//     setResendOtpCounts(pre => pre + 1);
//     otpSendingFunction(details.email).then(
//         () => {
//             setSecondsLeft(RESEND_OTP_DELAY_IN_SECONDS);
//             setIsResendButtonEnable(false);
//             secondsTimerCreater();
//         }
//     );
// }