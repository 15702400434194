import { useEffect } from "react";
import useUserSetup from "../useUserSetup";

const useUrlBeautifier = () => {
    const userSetUp = useUserSetup();
    return () => {
        const url = window.location.href

        if (url.includes("access_token")) {
            const param = {};
            url.split('&').map((item => {
                const list = item.split('=');
                if (list[0] === "access_token" || list[0] === "token_type" || list[0] === "expires_in" || list[0] === "scope") {
                    param[decodeURIComponent(list[0])] = decodeURIComponent(list[1]);
                }
            }));

            if (Object.keys(param).length > 0) {
                sessionStorage.setItem('authInfo', JSON.stringify(param));
                userSetUp(param?.access_token);
            }
            window.history.pushState({}, document.title, "/");

            // dispatch(updateAuthenticationStatus(true))
        }
    }

}

export default useUrlBeautifier;