import React from 'react'
import { PASSWORD_MIN_LENGTH } from '../../constents/numbers';

const Password = ({ setPasswordError, setPassword, password }) => {
    return (
        <div>
            <div className=' flex gap-2 items-end'>
                {/* <p className=' font-semibold'>*Email </p> */}
                {password.length < PASSWORD_MIN_LENGTH && password.length > 0 && (<p className=' text-sm text-red-700 font-semibold'>Password length is short</p>)}
            </div>
            <input
                className=' mb-4 p-4 outline-none rounded-md bg-[#f0f0f0] w-full'
                type='password'
                placeholder='Password'
                value={password}
                onChange={(e) => { setPassword(e.target.value) }}
            />
        </div>
    )
}

export default Password