import React, { useState } from 'react'
import { USER_REGISTER } from "../../Utils/apis";

const useRegister = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isUserCreated, setIsUserCreated] = useState(false);
    // const [isUserCreated, setIsUserCreated] = useState(true);

    const fn = async (email, password) => {

        if (!loading) {
            try {
                setLoading(true)
                const res = await fetch(USER_REGISTER,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email, password }),
                    }
                );

                if (res.ok) {
                    const user = await res.json();
                    window.localStorage.setItem('token', user.token);
                    setIsUserCreated(true);
                }
                else {
                    const err = await res.json();
                    throw new Error(err.error);
                }
            } catch (error) {
                setError(error.message);
            }
            finally {
                setLoading(false);
            }

            // return Promise.resolve('');
            // return info;
        }
    }

    return [loading, error, isUserCreated, fn];
}

export default useRegister