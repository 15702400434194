import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'

const Protection = ({ children }) => {


    if (window.sessionStorage.getItem('token')) {
        return children;
    }
    else {
        // window.history.replaceState({}, '', '/auth/login');
        // window.location.reload();
        Navigate('/auth/login')
    }
}
export default Protection;