import { createSlice } from "@reduxjs/toolkit";

import userProfile from '../../MOCK_DATA/user';

const user = createSlice({
    name: 'Applicant',
    initialState: {},
    // initialState: userProfile,
    reducers: {
        add: (state, action) => {
            return action.payload;
        }
    }
});

export const { add } = user.actions;
export default user.reducer; 