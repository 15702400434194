import React, { useState } from 'react'
import Header from '../Components/Header/Header'
import { DISCOVER_SECTION_TEXT } from '../constents/HeaderStrings'
import MainSearch from '../Components/Discover/MainSearch'
import Filters from '../Components/Discover/Filters'
import useFetchJobs from '../Hooks/useFetchJobs'
import { jobQueryFormatter } from '../constents/functions'
import Job from '../Components/Discover/Job'
import { useSelector } from 'react-redux'
import { Loader } from '../constents/components'
import useFetchRecommendedJobs from '../Hooks/jobs/useFetchRecommendedJobs'

const DiscoverSection = () => {
    const loadingtext = 'loading...'
    const [rloading, rerror, rJobs] = useFetchRecommendedJobs();
    const [filters, setFilters] = useState({ employmentType: [], locations: [], minSalary: 0, maxSalary: 120000000, })
    // const [filters, setFilters] = useState({ employmentType: ['Full Time'], locations: ['Pune'], minSalary: 60000, maxSalary: 90000, })
    const [query, setQuery] = useState('')

    const [loading, jobsError, jobsList, fetchFunction] = useFetchJobs();
    const fetch = () => fetchFunction({ query, ...filters });
    const list = useSelector((store) => store?.jobs?.[jobQueryFormatter({ query, ...filters })])

    return (
        <div >
            <Header text={DISCOVER_SECTION_TEXT} />
            <div className=' flex overflow-auto h-[calc(100vh-4rem)] '>
                <div className='w-1/2  flex-1 p-4'>
                    <MainSearch
                        setQuery={setQuery}
                        query={query}
                        fetch={fetch}
                    />

                    {
                        loading ? (<Loader />) : ( list && <div className='mt-6 border-[1.5px] border-gray-200 rounded-2xl overflow-hidden'>

                            {list?.map((item, index) => (<><Job key={index} job={item} /> <hr/></>))}
                        </div>)
                    }
                    {!list && rJobs &&
                        rloading ? (<Loader />) : (rJobs && <div className=' mt-6 border-[1.5px] border-gray-200 rounded-2xl overflow-hidden'>

                            {rJobs?.map((item, index) => (<><Job key={index} job={item} /> <hr/></>))}
                        </div>)
                    }

                </div>
                <Filters
                    filters={filters}
                    setFilters={setFilters}
                />
            </div>

        </div>
    )
}

export default DiscoverSection;