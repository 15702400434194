import React, { useEffect, useState } from 'react'
import { AuthStepsHeading, AuthStepsParagraph, ButtonWithIcon, InputBoxWraper } from '../../../constents/components';
import { PROFILE_IMAGE_WHILE_SIGNUP_LABEL_ID } from '../../../constents/htmlids'
import arrowIcon from '../../../Assets/arrow_back.svg';
import useProfileUpdater from '../../../Hooks/Registration/useProfileUpdater';
import { MAX_PROFILE_IMAGE_SIZE } from '../../../constents/numbers';

const Profile = ({ details, sendToNextStep, updateFunctions }) => {
    const { updateProfileImage } = updateFunctions;
    const requirementSummary = 'Setting up the profile for you..';

    const placeholder = 'Upload Profile Pic';;
    const [loading, error, url, profileChange] = useProfileUpdater();
    const [file, setFile] = useState({});
    const [localError, setLocalError] = useState('');


    const onNextButtonClick = async () => {
        const res = await profileChange(file);
        sendToNextStep();
    }
    useEffect(() => {
        setLocalError('');
        if (file?.size) {
            if (file.size >= MAX_PROFILE_IMAGE_SIZE) {
                setLocalError(`File size should be under ${MAX_PROFILE_IMAGE_SIZE / 1024}KB`);
            }
            else {
                updateProfileImage(URL.createObjectURL(file));
            }
        }
    }, [file]);

    return (
        <div className=' flex flex-col gap-4'>
            <p>{requirementSummary}</p>
            <InputBoxWraper classnames={"w-fit text-[#A9A9A9] "}>
                {loading && 'uploading...'}
                {!loading && (<>
                    <label
                        className=' cursor-pointer'
                        htmlFor={PROFILE_IMAGE_WHILE_SIGNUP_LABEL_ID}>
                        {placeholder}
                    </label>
                    <input
                        className=' hidden'
                        id={PROFILE_IMAGE_WHILE_SIGNUP_LABEL_ID}
                        type="file"
                        onChange={(e) => { setFile(e.target.files[0]) }}
                    />
                </>)}
            </InputBoxWraper>
            {localError && (<p className=' text-red-500'>{localError}</p>)}
            {
                details.profile ? (
                    <img
                        src={details.profile}
                        className=''
                        alt="profile-preview"
                    />
                )
                    :
                    (
                        <div className=' flex items-center  justify-center w-72 h-72 bg-gray-200 rounded-md'>
                            <p>Profile Pic Preview</p>
                        </div>
                    )
            }

            <ButtonWithIcon onclick={onNextButtonClick} text={"Continue"} icon={arrowIcon} classNames={'p-4 text-white'} iconClassnames={"rotate-180 h-4"} />
        </div>

    )
}

export default Profile;