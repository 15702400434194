import React from 'react'
import { AuthStepsHeading, AuthStepsParagraph } from '../../constents/components';
import { HR_PORTAL_DOMAIN } from '../../constents/domains';
import { useNavigate } from 'react-router-dom';

const AuthChild = () => {

    const heading = 'Let\'s get you started';
    const paragraph = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, similique sunt ipsam esse qui a ullam recusandae consequuntur.";

    const signUpOtions = [{
        heading: 'I\'m hiring a candidates',
        detail: 'Post & Manage jobs',
        redirect: HR_PORTAL_DOMAIN,
    },
    {
        heading: 'I\'m looking for job',
        detail: 'Apply & Manage your jobs applications',
        navigation: '/auth/register',
    }
    ];

    const Navigate = useNavigate();
    return (
        <div className='flex flex-col justify-between'>
            <AuthStepsHeading text={heading} />
            <AuthStepsParagraph text={paragraph} />
            <div className='flex flex-col gap-4 py-8'>
                {signUpOtions?.map((item, index) => (
                    <div
                        key={index}
                        className=' px-8 py-10 rounded-2xl bg-[#f0f0f0] cursor-pointer'
                        onClick={(e) => item?.redirect ? window.open(item?.redirect) : Navigate(item?.navigation)}
                    >
                        <h1 className=' text-[1.8rem]'>{item.heading}</h1>
                        <p className=' text-[1.1rem]'>{item.detail}</p>
                    </div>
                ))}
            </div>

            <p className=' text-gray-400'>Already have an account ? <span className='text-black cursor-pointer'  onClick={() => Navigate('/auth/login')}>Log in </span> </p>
            <p className='pt-1 text-gray-400 text-xs'>By continuing, you agree to Termns of Use and Privacy Policy  </p>
        </div>
    )
}

export default AuthChild;