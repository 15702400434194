import React, { useState } from 'react'
import { REQUEST_FORGOT_PASSWORD } from '../Utils/apis';
const useRequestForgotPassword = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [sucess, setSucess] = useState(false);


    const fn = async (email) => {
        if (!loading) {
            try {
                setLoading(true)
                const res = await fetch(REQUEST_FORGOT_PASSWORD, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                });

                if (res.ok) {
                    // return Promise.resolve("Request Sended succesfully");
                    setSucess(true);
                }
                else {
                    const err = await res.json();
                    throw new Error(err.error);
                }
            } catch (error) {
                setError(error.message);
                return Promise.reject(error.message);
            }
            finally {
                setLoading(false);
            }
        }
    }

    return [loading, error, sucess, fn];
}

export default useRequestForgotPassword;