import React from 'react'
import { ButtonWithoutIcon } from '../../constents/components';

const MainSearch = ({ fetch, query, setQuery }) => {


    return (
        <div className=''>
            <div className=' flex gap-4 border-[1.5px] p-4 rounded-2xl'>
                <img src="" alt="" />
                <input
                    value={query}
                    placeholder='Search for jobs, job title or companies'
                    className=' flex-1 outline-none border-none'
                    onChange={(e) => setQuery(e.target.value)}
                />
                <ButtonWithoutIcon
                    text={'search'}
                    classNames=' text-white px-6 py-1'
                    onclick={fetch}
                />
            </div>
        </div>
    )
}
export default MainSearch;